
// web运行信息
class RunInfoHelper {
  static ua = navigator.userAgent

  // 是否是苹果设备
  static isAppleDevice() {
    return this.ua.indexOf('Mac OS X') > -1
  }

  // 是否是安卓设备
  static isAndroidDevice() {
    return this.ua.indexOf('Android') > -1 || this.ua.indexOf('Linux') > -1
  }

  // 打开页面的app
  static openIn() {
    let openIn = ''
    let ua = this.ua
    ua = ua.toLowerCase()

    if (/weibo/i.test(ua)) {
      // 在新浪微博客户端打开
      openIn = 'weibo'
    }
    if (/qq/i.test(ua)) {
      // 在QQ程序中打开
      openIn = 'qq'
    }
    if (/chrome/i.test(ua)) {
      openIn = 'chrome'
    }
    if (/microMessenger/i.test(ua)) {
      // 在微信中打开
      openIn = 'weixin'
    }
    return openIn
  }
}
class AppDowloadHelper {
  // 下载安卓apk
  static downloadAndroidPackage(href, name = 'winbull') {
    // 创建隐藏的可下载链接

    var eleLink = document.createElement('a')
    eleLink.setAttribute('href', href)
    if (window.innerWidth < 768){ // 兼容pc端浏览器下载页面
      eleLink.setAttribute('download', name)
    }
    eleLink.style.display = 'none'
    // 触发点击
    document.body.appendChild(eleLink)
    eleLink.click()
    // 然后移除
    document.body.removeChild(eleLink)
  }

  // ios打开商店，android下载apk
  static openStoreOrDownloadApp({ browserNotAllow, apkUrl = '' }) {
    // 如在微信中打开，则通知用户使用手机浏览器打开
    if (['weixin', 'weibo', 'qq'].includes(RunInfoHelper.openIn())) {
      if (browserNotAllow) {
        browserNotAllow()
      }
      return
    }
    if (!apkUrl) return
    AppDowloadHelper.downloadAndroidPackage(apkUrl)
  }
}
export default AppDowloadHelper
