<template>
  <div class="wb-side-bar">
    <!-- 在线客服 -->
    <div class="btn-item" @click="onlineService" >
      <p>{{ $t('common.contact') }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'wb-side-bar',
    data() {
      return {
        scrollTop: 0,
        showPopover: true,
        url: '',
        showDownloadAppIcon: true
      }
    },

    methods: {
      onlineService() {
        window.easemobim.bind({ configId: '52c3eef3-a7db-4d6f-bf42-f8a58a05ccc6' })
      }
    }
  }
</script>

<style lang="scss" scoped>
.wb-side-bar {
  position: fixed;
  z-index: 999;
  right: 40px;
  bottom: 200px;
  display: flex;
  flex-direction: column;

  .btn-item {
    width: 70px;
    height: 72px;
    background-image:  url('../assets/images/common/icon_service_black.png');
    box-shadow: 0px 2px 16px 0px rgba(0,0,0,0.03);
    border-radius: 8px;
    position: relative;
    text-align: center;
    line-height: 72px;
    background-color: #fff;
    background-position: center 10px;
    background-size: 30px;
    background-repeat: no-repeat;
    cursor: pointer;
    &:hover {
      background-color:#0691ff ;
      background-image:  url('../assets/images/common/icon_service_white.png');
      p{
        color: #fff;
      }
    }

    &:active {
      background-color:#0474cc ;
      background-image: url('../assets/images/common/icon_service_white.png');
      p{
        color: #fff;
      }
    }
    p{
      margin-top: 16px;
      font-size: 13px;
      color: #333;
    }
  }
}
</style>
