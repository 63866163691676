// import parsePhoneNumber from 'libphonenumber-js'
import { isValidPhoneNumber as isValidPhoneNumberMobile } from 'libphonenumber-js/mobile'
import { isValidPhoneNumber as isValidPhoneNumberMin } from 'libphonenumber-js/min'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import uaParserJs from 'ua-parser-js'

// 验证是否是密码
export function isWbPassword(value) {
  if (!value || value.trim().length === 0) {
    return false
  } else {
    // 可以使用的字符[ASCII 33-126的字符]（数字、字母、英文特殊字符，不包含汉字、日文等其他字符）
    const passStr = value.replace(/[\x21-\x7e]/g, '')

    if (passStr.length === 0) {
      // 6-12位，包含字母、数字、符号中的两种
      const password = /^(?![A-Za-z]+$)(?!\d+$)(?![\W_]+$)\S{6,12}$/
      return password.test(value)
    } else {
      return false
    }
  }
}

// 校验手机号码格式是否正确
// prefixCode: 手机号码区号 => +86
// phoneNumber: 手机号码 => +8613800138000、13800138000、8613800138000、86-13800138000
export function phoneFormatValidate(prefixCode, phoneNumber) {
  if (!prefixCode || !phoneNumber) {
    return false
  }
  try {
    const phoneText = `${prefixCode}-${phoneNumber}`
    let flag
    if (prefixCode === '+86') {
      flag = isValidPhoneNumberMobile(phoneText)
    } else {
      flag = isValidPhoneNumberMin(phoneText)
    }
    // console.log(`phoneText: ${phoneText}, flag:${flag}`)
    return flag
  } catch (error) {
    return false
  }
}

// 生成用户验证状态【用于登录、注册、验证密码等】
// 1：账号相关
// 2：密码相关
// 3：验证码相关
// 4：账号锁定、禁用
// 0：其他错误
export function genUserVerifyState(code) {
  let state
  switch (code) {
    case 7000: // 无法找到该用户
    case 7006: // 手机号已经存在
    case 7007: // 手机号不存在
    case 7010: // 账号错误
    case 7011: // 电话号码格式错误
    case 7042:
      state = 1
      break

    case 7001: // 密码错误次数提示
    case 7004: // 密码不一致
    case 7005: // 密码规则校验失败
      state = 2
      break

    case 7208: // 验证码失效
    case 7209: // 验证码已被使用
    case 7008: // 手机验证码错误
    case 7015: // 邮箱验证码错误
      state = 3
      break

    case 7002: // 用户账户锁定
    case 7003: // 用户账户锁定
      state = 4
      break

    default:
      state = 0
  }
  return state
}

// 生成设备唯一uuid
export async function genDeviceUuid() {
  try {
    const fpPromise = FingerprintJS.load()
    const fp = await fpPromise
    const result = await fp.get()
    return result.visitorId
  } catch (error) {
    return ''
  }
}

// 生成登录使用的设备信息
export async function genDeviceInfo() {
  const uaParser = uaParserJs()
  console.log(uaParser)

  const deviceUdid = await genDeviceUuid()
  const deviceName = uaParser.browser.name + ':' + uaParser.browser.version
  const devicePlatform = uaParser.os.name
  const devicePlatformVersion = uaParser.os.version

  return {
    deviceUdid,
    deviceName,
    devicePlatform,
    devicePlatformVersion
  }
}

// 生成请求Device-Info信息
export async function buildReqDeviceInfo(isDownload = false) {
  const uaParser = uaParserJs()

  // const appName = 'winbull'
  const appName = 'h5'
  const appVersion = '1.0.0'
  const buildNumber = 1
  const deviceName = uaParser.device.model || uaParser.browser.name + ':' + uaParser.browser.version
  let platform = uaParser.os.name
  const deviceOSVersion = uaParser.os.version
  const udid = await genDeviceUuid()
  const channel = 'package'

  if (isDownload) {
    platform = 'Android'
  }

  const deviceInfo = `${appName}|${appVersion}|${buildNumber}|${deviceName}|${platform}|${deviceOSVersion}|${udid}|${channel}`

  return deviceInfo
}
