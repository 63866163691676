<template>
  <div class="wb-connect">
    <div class="contact-us">
      <div>
        <p class="title">{{ $t('footer.connectUs') }}</p>
        <br />
        <p class="phone">+852 2370 9538</p>
        <br />
        <p class="time">{{ $t('footer.tradingDay') }} 09:00-18:00</p>
        <br />
        <p class="email" style="margin-bottom: 0px;">{{ $t('footer.commonService') }} services@winbull.hk</p>
        <br />
        <p class="email">{{ $t('footer.businessService') }} info@winbull.hk</p>
      </div>
      <div>
        <p>
          <img class="down-logo" src="@/assets/images/common/img_IOS@2x.png" @click="toDownPage(iosLink)" /><br />
          <img class="down-logo" src="@/assets/images/common/img_google play@2x.png" @click="toDownPage(googleLink)" />
        </p>
        <p class="down-code" >
          <qrcode :options="{ size: 88}" />
        </p>
      </div>
    </div>
    <div class="desc">
      {{ $t('footer.winbull') }}{{ $t('aboutUs.prospectsDesc1.desc') }}{{ $t('aboutUs.prospectsDesc1.b')
      }}{{ $t('aboutUs.prospectsDesc1.desc2') }}{{ $t('aboutUs.prospectsDesc1.b2') }}。
    </div>
    <div class="page-mask" v-if="showMask" @click="toggleMask">
      <img class="tips-arrow" src="@/assets/images/common/icon_arrows_h5.png" />
      <div class="tips-box">
        <div class="tips-row">
          <div class="tips-text">{{ $t('common.openDownloadTips1') }}</div>
          <img class="more-icon" src="@/assets/images/common/icon_open_web.png" />
        </div>
        <div class="tips-row">
          <div class="tips-text">{{ $t('common.openDownloadTips2') }}</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import i18n from '@/language'
  import qrcode from '@/components/qrcode'
  import { getAppInfo } from '@/api'
  import AppDowloadHelper from '@/utils/app-download-tool.js'
  export default {
    name: 'wb-connect',
    components: {
      qrcode
    },
    data() {
      return {
        isShowQrCode: false,
        wechatIcon: require('@/assets/images/common/icon_wechat_color.png'),
        locale: i18n.locale,
        iosLink: 'https://apps.apple.com/hk/app/id1601778743',
        googleLink: 'https://play.google.com/store/apps/details?id=vip.winbull.invest.app',
        showMask: false
      }
    },

    mounted() {
      const _this = this
      document.body.addEventListener(
        'click',
        function() {
          _this.isShowQrCode = false
          _this.wechatIcon = require('@/assets/images/common/icon_wechat_color.png')
        },
        false
      )
      this.initVersion()
    },

    methods: {
      iconMouseenter() {
        if (!this.isShowQrCode) {
          this.wechatIcon = require('@/assets/images/common/icon_wechat_hover.png')
        }
      },
      iconMouseleave() {
        if (!this.isShowQrCode) {
          this.wechatIcon = require('@/assets/images/common/icon_wechat_color.png')
        }
      },
      iconClick() {
        this.isShowQrCode = !this.isShowQrCode
        this.wechatIcon = this.isShowQrCode
          ? require('@/assets/images/common/icon_wechat_click.png')
          : require('@/assets/images/common/icon_wechat_color.png')
      },
      toDownPage(link) {
        AppDowloadHelper.openStoreOrDownloadApp({
          browserNotAllow: this.toggleMask,
          apkUrl: link
        })
      },
      async initVersion(){
        const { data } = await getAppInfo()
        const ios = data.find(item => {
          return item.channel === 'appstore'
        })
        if (ios && ios.apkUrl){
          this.iosLink = ios.apkUrl
        }
        const googlePlay = data.find(item => {
          return item.channel === 'googlePlay'
        })
        if (googlePlay && googlePlay.apkUrl){
          this.googleLink = googlePlay.apkUrl
        }
      },
      toggleMask() {
        this.showMask = !this.showMask
      }

    }
  }
</script>

<style lang="scss" scoped>
.wb-connect {
  color: #fff;
  padding: 52px 0px 40px;
  max-width: 1000px;
  margin: 0 auto;

  .contact-us {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    p {
      display: inline-block;
      line-height: 20px;
    }

    .down-logo {
      width: 120px;
      cursor: pointer;
      &:nth-child(1) {
        margin-bottom: 13px;
      }
    }

    .down-code {
      width: 88px;
      height: 88px;
      overflow: hidden;
      border-radius: 4px;
      margin-left: 24px;
      background-color: #fff;
      position: relative;
     ::v-deep img{
        width: calc(100% - 8px);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }

    .title {
      font-size: 18px;
      border-bottom: 2px solid #0691ff;
      margin-bottom: 16px;
      font-weight: 500;
    }

    .phone {
      font-size: 24px;
      margin-bottom: 12px;
      font-weight:400;
    }

    .time,
    .email {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }

  .desc {
    font-size: 14px;
    line-height: 24px;
  }
}
.page-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999;

  .tips-arrow {
    position: absolute;
    top: 12px;
    right: 16px;
    width: 50px;
    height: 63px;
  }

  .tips-box {
    position: absolute;
    top: 81px;
    right: 16px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px;

    .tips-row {
      display: flex;
      justify-content: start;
      align-items: center;
    }

    .tips-text {
      font-size: 16px;
      color: #ffffff;
      line-height: 25px;
      font-weight: bold;
    }

    .more-icon {
      margin-left: 5px;
      width: 30px;
      height: 17px;
    }
  }
}

@media (min-width:1024px) and (max-width:1024px) {
  .wb-connect{
    padding: 42px 112px 35px;
    max-width: 100%;
    .desc{
      font-size: 11px;
      line-height: 18px;
    }

    .contact-us {
      .title{
        font-size: 14px;
        margin-bottom: 12px;
      }
      .phone{
        font-size: 19px;
        margin-bottom: 9px;
      }
      .time{
        font-size: 11px;
      }
      .down-logo{
        width: 97px;
        &:nth-child(1) {
          margin-bottom: 12px;
        }
      }
      .down-code{
        width: 70px;
        height: 70px;
        overflow: hidden;
        border-radius: 3px;
        ::v-deep img{
          width: calc(100% - 6px);
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width:1023px){
  .wb-connect {
    padding: 40px 32px;

    .contact-us {
      align-items: flex-end;
      .title {
        line-height: 24px;
        font-size: 18px;
        margin-bottom: 14px;
      }

      .phone {
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 12px;
      }
      .down-logo {
        width: 120px;
        &:nth-child(1) {
          margin-bottom: 12px;
        }
      }
      .down-code {
        width: 88px;
      }
      .time {
        margin-bottom: 4px;
        line-height: 20px;
      }
      .email{
        line-height: 20px;
      }
    }

    .desc,
    .time,
    .email {
      font-size: 14px;
    }
  }
}
@media screen and (max-width:767px) {
  .wb-connect {
    padding: 40px 20px;

    .contact-us {
      .title {
        font-size: 16px;
      }
      .phone {
        font-weight: 500;
        font-size: 18px;
      }
      .down-code{
        display: none;
      }
      .down-logo{
        width: 120px;
      }
      .time,.email,.desc{
        font-size: 14px;
        &.email:first-child {
          margin-bottom: 4px;
        }
      }
    }
    .desc{
      line-height: 22px;
    }
  }
  .email:nth-last-child(1){
    margin-top: 4px!important;
  }
}
</style>
