<template>
  <div class="wb-footer-info">
    <div class="content">
      <div class="cp-r">
        <span>Copyright © {{ $t('footer.company') }}</span>
        <span>{{ $t('footer.warning') }}</span>
      </div>
      <div class="xieyi">
        <span @click="openPdf('fwtk')">{{ $t('footer.fwtk') }}</span>
        <span @click="openPdf('khxy')">{{ $t('footer.khxy') }}</span>
        <span @click="openPdf('mzsm')">{{ $t('footer.mzsm') }}</span>
        <span @click="openPdf('wlaq')">{{ $t('footer.wlaq') }}</span>
        <span @click="openPdf('yszc')">{{ $t('footer.yszc') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import i18n from '@/language'
  import AppVersionInfo from '@/utils/app-version-info.js'

  export default {
    name: 'wb-footer-info',

    data() {
      return {
        androidVersion: '',
        iosVersion: '',
        updateTime: ''
      }
    },

    async mounted() {
      try {
        await AppVersionInfo.getCurrentAppInfo()
        this.androidVersion = AppVersionInfo.androidVersion || '-'
        this.iosVersion = AppVersionInfo.iosVersion || '-'
        this.updateTime = AppVersionInfo.updateTime || '-'
      } catch (error) {
        this.androidVersion = '-'
        this.iosVersion = '-'
        this.updateTime = '-'
      }
    },

    methods: {
      openPdf(name) {
        const params = {
          fwtk: '服務條款',
          khxy: '客戶協議',
          mzsm: '免責聲明',
          wlaq: '網絡安全',
          yszc: '私隱政策及個人資料收集聲明'
        }
        let encodeTitle = encodeURIComponent(params[name])
        if (name === 'khxy') {
          encodeTitle = i18n.locale === 'zh_CN' ? 'customer_agreement_cn' : 'customer_agreement_hk'
        }
        window.open(`https://offical.winbull8.com/policy_agreement/${encodeTitle}.pdf`, '_blank')
      }
    }
  }
</script>

<style lang="scss" scoped>
.wb-footer-info {
  background: #4d4d4d;
  height: 62px;

  color: #999;
  font-size: 13px;

  .content {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .xieyi {
    span {
      cursor: pointer;
      margin-right: 4px;
      position: relative;
      display: inline-block;

      &:not(:nth-last-child(1))::after {
        content: '|';
        display: inline-block;
        margin-left: 8px;
        margin-right: 4px;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  .cp-r {
    span:first-child {
      display: inline-block;
      margin-right: 16px;
    }
  }
}

@media screen and (max-width:1024px) {
  .wb-footer-info {
    font-size: 10px;

    .content {
      padding: 16px 112px 20px;
      max-width: 100%;
    }

    .cp-r {
      span:first-child {
        margin-right: 12px;
      }
    }

    .xieyi {
      span {
        margin-right: 3px;
        &:not(:nth-last-child(1))::after {
          margin-left: 5px;
          margin-right: 2px;
        }
      }
    }
  }
}
@media  (min-width: 768px) and (max-width:1023px) {
  .wb-footer-info {
    height: auto;

    .content {
      flex-direction: column-reverse;
      padding: 20px 32px;
    }

    .xieyi {
      width: 100%;

      span {
        margin-bottom: 8px;
        line-height: 20px;

        &:nth-child(3) {
          width: auto;

          &::after {
            content: '';
          }
        }
      }
    }

    .cp-r {
      width: 100%;
      margin-top: 8px;
      line-height: 20px;

      span:first-child {
        display: inline-block;
        margin-bottom: 4px;
      }
    }
  }
}
@media screen and (max-width:767px) {
  .wb-footer-info {
    height: auto;

    .content {
      flex-direction: column-reverse;
      padding: 16px 20px 20px;
    }

    .xieyi {
      width: 100%;

      span {
        margin-bottom: 8px;
        line-height: 20px;
        &:nth-child(3) {
          width: calc(100% - 142px);
        }
      }
    }

    .cp-r {
      width: 100%;
      line-height: 20px;
      margin-top: 8px;
      span:first-child {
        display: block;
        margin-bottom: 4px;
      }
    }
  }
}

</style>
