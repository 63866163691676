// activityPortfolio2
export default {
  pageTitle: '盈寶投資組合福利巨獻',
  shareTitle: '盈寶投資組合福利巨獻！',
  shareDesc: '開戶送股票，認購送現金！',

  btnTxt: '立即認購',
  reward: '獎勵',
  joinUser: '參與用戶：',
  joinCondition: '參與條件：',
  usCurrency: '美元',
  moreThan: '以上',
  futeStock: '股福特',
  coupon: '現金券',
  relatedPost: '相關文章',
  annualized: '年化',
  maximum: '最大',

  cardOneTitle1: '開戶送股票',
  cardOneValue11: '首筆入金達2萬(含)港幣以上的新用戶',
  cardOneValue12: '認購任一個盈寶投資組合，認購即獎，無持有時間限制',
  cardOneTable1Col1: '首筆認購金額',
  cardOneTable1Col2: '認購即獎',
  cardOneTitle2: '首次認購送現金',
  cardOneValue21: '首次認購盈寶投資組合的用戶',
  cardOneValue22: '認購任一個盈寶投資組合，連續持有滿60天且投資組合無轉出資金',
  cardOneTable2Col1: '首筆認購金額',
  cardOneTable2Col2: '連續持有60天',
  cardOneTips: '註：用戶如滿足以上兩種條件，可同時享有雙重獎勵',

  cardTwoTitle: '市場表現',
  cardTwoTableHeader1: '組合名稱',
  cardTwoTableHeader2: '盈寶穩健<br/>收益寶',
  cardTwoTableHeader3: '盈寶全球<br/>ETF收益增強',
  cardTwoTableHeader4: '盈寶全球<br/>精選ETF輪動',
  cardTwoTableLabel1: '收益率',
  cardTwoTableLabel2: '回撤率',
  cardTwoTableLabel3: '美國市場',
  cardTwoTableLabel4: '香港市場',
  cardTwoTableValue1: '債券基金',
  cardTwoTableValue2: '混合基金',
  cardTwoTableValue3: '股票基金',
  cardTwoTips1:
    'wind統計：2023.03.06-2024.03.06；以上為三個盈寶投資組合分別比較美國、香港市場共3萬隻同類型基金的業績表現。',
  cardTwoTips2: '註：歷史收益率及回撤率僅供參考，投資組合的過往表現不能預示日後的表現。',

  cardTwoArticle1: '盈寶穩健收益寶超目標收益率47.8%，申購潮持續火熱！',
  cardTwoArticle2: '盈寶收益增強組合突飛猛進，表現繼續超過香港9成以上同類基金',
  cardTwoArticle3: '年化報酬率超20%，盈寶ETF輪動組合超港美市場1萬多檔基金',

  cardThreeTitle: '新手必讀',
  cardThreeLabel1: '盈寶投資組合的投資理念',
  cardThreeLabel2: '盈寶投資組合的投資策略',
  cardThreeLabel3: '盈寶投資組合的產品介紹',
  cardThreeLabel4: '認購盈寶投資組合是否安全',
  cardThreeLabel5: '如何選擇適合自己的投資組合',
  cardThreeLabel6: '如何做好投資資金及投資期限規劃',

  cardFourTitle: '常見問題',
  cardFourQ1: '獎勵如何發放？',
  cardFourA1:
    '1、活動期間滿足首筆入金條件的用戶，成功認購任意一個盈寶投資組合，按用戶首筆認購金額所應獲得的對應獎勵，系統48小時內自動派發福特（NYSE: F）股票券到 卡券中心，用戶在【我的】-【我的卡券】進行領取激活，卡券派發後15天內如未激活使用，則會失效，將視為用戶自動放棄。<br/>2、活動期間符合首次認購盈寶投資組合的用戶，依用戶首筆認購金額所應獲得的對應獎勵，連續持有滿60天且投資組合無轉出資金，達到條件後系統48 小時內自動派發港幣現金券到卡券中心，用戶在【我的】-【我的卡券】進行領取激活，卡券派發後15天內如未激活使用，則會失效，將視為用戶自動放棄。',
  cardFourQ2: '在哪裡認購盈寶投資組合？',
  cardFourA2: '盈寶證券APP-投資組合頻道，選擇你想認購的投資組合，點選【投入】按鈕並輸入投入金額確認提交。',
  cardFourQ3: '帳號只有港元，可以認購嗎？',
  cardFourA3: '盈寶投資組合需用美元認購，如用戶帳戶只有港元也可以直接認購，系統會自動進行換匯認購。',
  cardFourQ4: '認購後，在哪裡查看我的投資組合收益？',
  cardFourA4: '可以在盈寶證券APP-【資產】頻道查看組合收益狀況。',
  cardFourQ5: '是否有認購費，贖回費？',
  cardFourA5: '認購盈寶投資組合，不收取認購費和贖回費。',
  cardFourQ6: '盈寶投資組合管理費是多少？',
  cardFourA6:
    '盈寶穩健收益寶管理費為1%（年率），盈寶全球ETF收益增強管理費1.5%（年率），盈寶全球精選ETF輪動管理費2%（年率）。',

  pageTips:
    '免責聲明：<br/>1、此活動由盈寶證券（香港）有限公司提供。本活動並非及不應被視為任何證券、金融產品或工具邀約、招攬、邀請、建議、意見或任何投資決策的建議，亦不應被視為專業意見。流覽本活動的人士在作出任何投資決定之前，應完全了解其風險以及相關法律，涉及的稅項及會計的特點及後果，並根據個人的情況決定投資是否適合個人的財政狀況及投資目標，以及有關風險的承受能力，有需要時應尋找適當的專業意見。<br/>2、盈寶證券（香港）在法律規定範圍內保留更改此條款及細則，更改或終止任何優惠的權利，而毋須另行通知。本活動的獎勵資格及資料確認，以盈寶證券（香港）後台資料為準，如有任何爭議，盈寶證券（香港）保留最終解釋權及決定權。<br/>3、如對本次活動有任何查詢需要，請致電盈寶證券（香港）客服專線（852）23709538。 '
}
