// activityDoubleFestivals
export default {
  pageTitle: '盈宝双旦三重礼',
  shareTitle: '盈宝双旦三重礼 重重有惊喜',
  shareDesc: '奖励高达万元港币，畅享双旦',

  immediatelyJoin: '立即参与免佣福利',

  cardOneTitle1: '新注册用户首次入金达标',
  cardOneTitle2: '奖励高达 HKD 4300',
  cardOneHeaderFlag: '一重礼',
  cardOneTabelHeader1: '首入金金额(HKD)',
  cardOneTabelHeader2: '奖励',
  worth: '价值',
  newUserGift: '新人礼包',
  stockCard: '股票卡',
  futeStock: '福特(NYSE:F)',
  stockCount: '股',
  cardOneTips: '提示：获得股票卡需在首入金达标后留存30天并交易≥1次；新人礼包请',
  seeMore: '查看详情',

  cardTwoTitle1: '交易港/美股日内融笔数达标',
  cardTwoTitle2: '奖励高达 HKD 1500',
  cardTwoHeaderFlag: '二重礼',
  cardTwoTabelHeader1: '交易港/美股数',
  cardTwoTabelHeader12: '日内融笔',
  cardTwoTabelHeader2: '奖励金额',
  cardTwoTabelHeader3: '累计奖励',
  cashCoupon: '现金券',
  tradeCount: '笔',
  cardTwoTips: '提示：交易美股每笔金额需大于或等于10美元。',

  cardThreeTitle1: '盈宝正股融资 0 利息',
  cardThreeTitle2: '高达 HKD 300万 融资免息',
  cardThreeHeaderFlag: '三重礼',
  cardThreeDesc: '参与融资活动的客户，累计最高可使用300万港元，直至活动结束；逾期不予计入',
  cardThreeStep1: '领取免息福利',
  cardThreeStep2: '资产 - 融资',
  cardThreeStep3: '融资交易',
  cardThreeStep4: '活动结束',
  cardThreeStep5: '利息返现',
  cardThreeStep6: '到账',
  cardThreeTips: '提示：活动期间免息额度所产生的利息，采用先收后返原则。',

  activityRule: `
    一、活动对象：盈宝证券用户<br/>
    二、活动时间：2023.12.22 0:00--2024.1.31 23:00<br/>
    三、活动条件及奖励<br/>
    一重礼活动规则：<br/>
    1）活动期间，成功首次入金达到对应要求即可获得对应的股票卡；自入金到账次日起30天内无出金记录且30天内交易1次可获得对应的股票券。（特别说明：交易一次港股或美股一次，不含申购货币基金及新股） 注：活动参与条件为未入过金的新用户即首次入金用户。首次入金成功（20000，30000】的用户，可获得价值HKD3800新人礼包，具体以新人活动为准。<br/>
    2）活动期间，首次入金成功（30001，50000】的用户，可获得价值HKD3800新人礼包+福特 (NYSE: F) - 1股，需满足留存30天且交易≥1次。<br/>
    3）活动期间，首次入金成功（50001，100000】的用户，可获得价值HKD3800新人礼包+福特 (NYSE: F) - 2股，需满足留存30天且交易≥1次。<br/>
    4）活动期间，首次入金成功（100001，200000】的用户，可获得价值HKD3800新人礼包+福特 (NYSE: F) - 3股，需满足留存30天且交易≥1次。<br/>
    5）活动期间，首次入金成功（200001，300000】的用户，可获得价值HKD3800新人礼包+福特 (NYSE: F) - 5股，需满足留存30天且交易≥1次。<br/>
    6）价值HKD3800新人礼包：注册就送88HKD礼包（30天美股lv1行情券）、开户就送2256HKD礼包（180天港股lv2+180天美股lv1）、首笔入金金额达标最高赠送1500HKD现金券。 注：实际以新人活动规则为准。<br/><br/>
    二重礼活动规则：<br/>
    1）活动期间，用户每完成一个梯度的港美股日内融交易笔数要求（买卖各算一笔），可获得对应奖励；<br/>
    2）客户活动期间累计交易港/美股日内融30笔获得100现金，再交易20笔累计交易50笔 再获得100现金，累计共获得200现金，以此类推，最高可获得1500现金。<br/>
    3）交易笔数要求：港/美股日内融交易中，买卖各算一笔。交易须在活动期间（北京时间）内完成成交，超出活动时间的交易不计入交易笔数。特别说明，交易美股每笔金额不得低于10美元，且每笔低于10美元的成交将不算再累计笔数当中。<br/><br/>
    三重礼活动规则：<br/>
    1）参与活动的用户在活动期间均可使用日均10万港元的孖展融资免息额度，累计300w的免息额度，直至活动结束；孖展融资免息额度使用时间截止至活动最后1天，逾期不予计入。（注：融资申购新股不参与此活动）<br/>
    2）用户若在活动期间使用孖展融资额度超过免息的部分，超出部分将正常收取费用，融资金额及产生的利息可查询日结单，如有任何查询，请致电本公司客服部。<br/>
    3）活动期间内单笔免息额度不得超过用户保证金账户自身的可抵押金额；用户在活动期间累计免息额度最高不超过300万港元；（如用户A在活动期间1天内用了300万，后续再融资则需要收取费用，按实际超出的融资额度进行计息。）<br/>
    4）活动奖励：活动期间免息额度所产生的利息，采用先收后返原则，即在活动过程中正常收取，活动结束后统一返现到证券账户。<br/><br/>

    四、奖励发放说明<br/>
    一重礼奖励说明：<br/>
    1）首次入金达标可获得对应的价值HKD3800新人礼包，具体可查看新人活动奖励规则。<br/>
    2）股票卡需满足对应的新增资金要求，达标后系统会自动发放股票卡券至个人账户，可前往我的卡券查看，逾期未兑换，不另补发。<br/><br/>
    二重礼奖励说明：<br/>
    1）现金券需满足对应的交易笔数要求，达标后系统会自动发放卡券至个人账户，逾期未兑换，不另补发; <br/>
    2）现金券奖励将卡券形式发放领取有效期为15天，逾期不另补发；<br/>
    3）交易数据同步至卡券系统有1个交易日延时，请在完成符合指定条件的订单后下一个交易日再行查看使用；<br/>
    4）卡券使用说明最终以“我的卡券”中为准。<br/><br/>
    三重礼奖励说明：<br/>
    1）活动期间，正股融资利息采用先收后返原则，活动结束后的15个工作日内，活动免息部分将统一以港元币种返还到用户的盈宝账户，因不同币种的汇率存在变化，最终返还金额的换算汇率以我司系统数据为准。<br/><br/>

    五、其他细则及免责声明<br/>
    1）同一用户仅能参与一次活动，同一盈宝ID号、同一手机号、同一设备均视为同一用户；<br/>
    2）活动奖励均严格按照规则发放，如有任何不符合活动获奖资格的情况，均无法特殊申请补发奖励，请遵守活动规则；<br/>
    3）活动奖励设置有效期，若未在有效期内及时使用，过期后将不予以补发奖励，视为自动放弃获奖资格；<br/>
    4）如发现包括但不限于通过软件恶意刷奖、手机号码异常、盗取他人信息、邀请人IP异常等行为，盈宝证券有权取消或冻结其活动奖励;<br/>
    5）此活动由盈宝证券国际(香港)有限公司("盈宝证券")提供。本活动并非及不应被视为任何证券、金融产品或工具邀约、要约、招揽、邀请、建议、意见或任何投资决策的见议，亦不应被视为专业意见。浏览本活动的人士在作出任何投资决定之前，应完全了解其风险以及相关法律，涉及的税项及会计的特点及后果，并根据个人的情况决定投资是否适合个人的财政状况及投资目标，以及有关风险的承受能力，有需要时应寻找适当的专业意见。本广告由本公司提供，内容未经香港证券及期货事务监察委员会审閲。盈宝证券保留更改活动条款及细则⼂更改或终止任何优惠的权利，而毋须另行通知。本活动的奖励资格及数据确认以盈宝证券后台数据为准，若有任何争议，盈宝证券保留本活动的最终解释权及决定权，如有问题可咨询在线客服或电话： 香港：85223709538，内地：4008016688。<br/>
  `
}
