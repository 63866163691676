// activityStockTrans
export default {
  immediatelyOpen: '立即開戶',
  activityRule: '活動規則',
  activityTime: '活動時間',

  routerTitle: '轉倉活動',

  shareTitle: '轉倉盈寶交易',
  shareDesc: '報銷轉倉手續費 送免佣大補貼',

  joinActivity: '參與活動',
  bannerText1: '報銷 轉倉手續費',
  bannerText2: '送 免佣大補貼',
  bannerTipTitle: '溫馨提示：',
  bannerTips1: '1、活動面向未入金且未轉倉的用戶',
  bannerTips2: '2、轉倉報銷費用以實際產生的為準，需提供扣費憑證給在線客服登記或發郵件登記（services@winbull.hk），需在成功轉倉後的7個工作天內完成，若超過時間未申請將享有不了報銷補助。',

  cardOneTitle: '港美股轉倉操作 二選一',
  cardOneTips1: '注：需保持30天內不出金並且交易≥1次',
  cardOneIntroTitle1: '01. 港股累計轉倉 ≥ HKD 30,000',
  cardOneIntroDesc11: '30天港股免佣券 x 2',
  cardOneIntroDesc12: '最高報銷 500港元轉倉費用',
  cardOneIntroWidth: '或',
  cardOneIntroTitle2: '02. 美股累計轉倉 ≥ USD 4,000',
  cardOneIntroDesc21: '30天美股免佣券 x 2',
  cardOneIntroDesc22: '最高報銷 100美元轉倉費用',
  cardOneTips2: '注：轉倉報銷費用以實際產生的為準，需提供扣費憑證給在線客服登記或發郵件登記（services@winbull.hk），需在成功轉倉後的7個工作天內完成，若超過時間未申請將享有不了報銷補助。',

  cardTwoTitle: '如何轉倉到盈寶',
  cardTwoText1: '選擇轉出券商',
  cardTwoText2: '填寫股票信息',
  cardTwoText3: '聯繫對方券商',
  cardTwoTips1: '具體操作指引',
  cardTwoTips2: '港股轉入說明',
  cardTwoTips3: '美股轉入說明',

  cardThreeTitle: '為什麼選擇盈寶',
  cardThreeIntroTitle1: '持牌券商（編號：BRG131）',
  cardThreeIntroDesc1: '獲認可的互聯網券商，擁有1、4、9牌照',
  cardThreeIntroTitle2: '快人一步',
  cardThreeIntroDesc2: '毫秒級下單速度，第一時間掌握交易先機',
  cardThreeIntroTitle3: '一站式交易',
  cardThreeIntroDesc3: '支持港股、美股、基金等投資標的',
  cardThreeIntroTitle4: '極致客戶服務',
  cardThreeIntroDesc4: '7x24小時在線客服，一對一解答疑問'
}
