
import Quill from 'quill'
import '@/components/Emoji/emoji'
import { EmojiList, EmojiList2, EmojiRule } from '@/components/Emoji/list'
const stackRule = /\$[^\$\*\+\?\\\^\{\}\|\n]+\([a-zA-Z0-9]+\.[A-Z]+\)\$/g
/* @description: 解析富文本文字与表情包
* @return {*}
*/
export function getText(json_str) {
  const node = document.createElement('div')
  const quill = new Quill(node, { readOnly: true })
  try {
    quill.setContents(JSON.parse(json_str))
  } catch {
    return json_str
  }
  const text = quill.getContents().ops.map(op => {
    if (op.attributes && op.attributes.link) { // 获取股票链接
      const value = op.attributes.link
      if (stackRule.test(value)) {
        const stockText = value.replace(/\$/g, '').replace(/\([a-zA-Z0-9]+\.[A-Z]+\)/, '')
        return `<span class="ql-stock">${stockText}</span>`
      } else {
        return ''
      }
    } else if (typeof op.insert === 'string') {
      return op.insert.replace(/<script>/g, '&lt;script&gt;').replace(/<\/script>/g, '&lt;&#x2F;script&gt;')
    } else if (op.insert.emoji) { // 获取表情包
      const emoji = op.insert.emoji
      return emoji.replace(EmojiRule, (value) => {
        let emojiText = value.substring(1, value.length - 1)
        const index = EmojiList2.indexOf(emojiText)
        if (index > -1) {
          emojiText = EmojiList[index]
          if (emojiText) {
            return `<img class="ql-emoji" src="https://winbull-img2.oss-cn-guangzhou.aliyuncs.com/emoji_64/[${emojiText}].png">`
          }
        }
        return ''
      })
    } else {
      return ''
    }
  }).join('').replace(/\n$/g, '').replace(/\n\n\n*/g, '\n').replace(/\s\s\s*/g, ' ')
  return text
}
/**
 * @description: 解析富文本所有内容，包括表情包，股票与图片
 * @return {*}
 */
export function getAllContext(json_str) {
  const node = document.createElement('div')
  const quill = new Quill(node, { readOnly: true })
  try {
    quill.setContents(JSON.parse(json_str))
  } catch {
    return json_str
  }
  quill.setContents(JSON.parse(json_str))
  const text = quill.getContents().ops.map(op => {
    if (op.attributes && op.attributes.link) { // 获取股票链接
      const value = op.attributes.link
      if (stackRule.test(value)) {
        const stockText = value.replace(/\$/g, '').replace(/\([a-zA-Z0-9]+\.[A-Z]+\)/, '')
        return `<span class="ql-stock">${stockText}</span>`
      } else {
        return ''
      }
    } else if (typeof op.insert === 'string') {
      return op.insert.replace(/<script>/g, '&lt;script&gt;').replace(/<\/script>/g, '&lt;&#x2F;script&gt;')
    } else if (op.insert.image && typeof (op.insert.image) === 'string') { // 获取上传图片
      return `<img class="ql-img" src="${op.insert.image}">`
    } else if (op.insert.emoji) { // 获取表情包
      const emoji = op.insert.emoji
      return emoji.replace(EmojiRule, (value) => {
        let emojiText = value.substring(1, value.length - 1)
        const index = EmojiList2.indexOf(emojiText)
        if (index > -1) {
          emojiText = EmojiList[index]
          if (emojiText) {
            return `<img class="ql-emoji" src="https://winbull-img2.oss-cn-guangzhou.aliyuncs.com/emoji_64/[${emojiText}].png">`
          }
        }
        return ''
      })
    } else {
      return ''
    }
  }).join('').replace(/\n$/g, '').replace(/\n\n\n*/g, '\n').replace(/\s\s\s*/g, ' ')
  return text
}
/**
 * @description: 只解析文字
 * @return {*}
 */
export function getOnlyText(json_str) {
  const node = document.createElement('div')
  const quill = new Quill(node, { readOnly: true })
  try {
    quill.setContents(JSON.parse(json_str))
  } catch {
    return json_str
  }
  const text = quill.getContents().ops.map(op => {
    if (typeof op.insert === 'string') {
      return op.insert.replace(/<script>/g, '&lt;script&gt;').replace(/<\/script>/g, '&lt;&#x2F;script&gt;')
    } else {
      return ''
    }
  }).join('').replace(/\n$/g, '').replace(/\n\n\n*/g, '\n').replace(/\s\s\s*/g, ' ')
  return text
}
// 设置搜索结果包含搜索关键字样式
export function searchStyle(value, content) {
  if (!value) return
  const reg = new RegExp(value, 'g')
  content = content.replace(reg, `<span class="search-key">${value}</span>`)
  return content
}
