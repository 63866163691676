// activityStockTrans2
export default {
  immediatelyOpen: '立即开户',
  activityRule: '活动规则',
  activityTime: '活动时间',

  routerTitle: '转仓活动',
  shareTitle: '转仓盈宝即赚，最高 HK$3800 补贴！',
  shareDesc: '新老客户专享福利，速抢双重礼！',

  joinActivity: '马上转仓',
  accumulatedAmount: '累计净转仓金额(或等值)',
  reward: '奖励',
  futeStock: '福特',
  stockUnit: '股',
  orAbove: '或以上',
  give: '送',

  cardOneTitle: '新客户首次转仓最高赠送<span>HKD2800</span>礼包',
  cardOneOther: '再额外报销转仓费用最高500港元或100美元',
  cardOneTips:
    '注：新客户是指首次转仓且在之前注册未有入金且未转仓的客户。获得奖励需保持30天不出金且股票交易大于或等于一次。',

  cardTwoTitle: '老客户转仓最高赠送<span>HKD1800</span>礼包',
  cardTwoOther: '再额外报销转仓费用最高500港元或100美元',
  cardTwoTips: '注：老客户是指在盈宝已入金或已转仓客户。<br/>获得奖励需保持30天不出金且股票交易大于等于一次。',

  cardThreeTitle: '如何转仓到盈宝',
  cardThreeText1: '选择转出券商',
  cardThreeText2: '填写股票信息',
  cardThreeText3: '联系对方券商',
  cardThreeTipsLabel: '具体操作指引',
  cardThreeTips2: '港股转入说明',
  cardThreeTips3: '美股转入说明',

  cardFourTitle: '为什么选择盈宝',
  cardFourIntroTitle1: '持牌券商（编号：BRG131）',
  cardFourIntroDesc1: '获认可的互联网券商，拥有1、4、9牌照',
  cardFourIntroTitle2: '快人一步',
  cardFourIntroDesc2: '毫秒级下单速度，第一时间掌握交易先机',
  cardFourIntroTitle3: '一站式交易',
  cardFourIntroDesc3: '支持港股、美股、基金等投资标的',
  cardFourIntroTitle4: '极致客户服务',
  cardFourIntroDesc4: '7x24小时在线客服，一对一解答疑问',

  pageTips:
    '注：转仓报销费用以实际产生的为准，需提供扣费凭证给在线客服登记或发邮件登记（services@winbull.hk），需在成功转仓后的14个工作天内完成，若超过时间未申请将享有不了报销补助。'
}
