// activityPortfolioFixed
export default {
  pageTitle: '盈寶投資組合定投活動',
  shareTitle: '盈寶限時獎勵活動',
  shareDesc: '購買投資組合最高獎勵1800HKD！ ',
  reward: '獎勵',

  cardOneTitle: '活動獎勵',
  cardOneDesc: '在活動期間持有投資組合達標且持有時長達到要求',
  cardOneLabel1: '持有30天且持有市值≥USD5000',
  cardOneLabel2: '持有60天且持有市值≥USD10000',
  cardOneLabel3: '持有90天且持有市值≥USD15000',
  cardOneTips: '註：持有時長是從達到活動要求的持有市值開始計算。',

  cardTwoTitle: '活動試算',
  cardTwoDesc1: '獎勵從低到高，定投時間越長，獲得的獎勵越高；',
  cardTwoDesc2: '以ETF輪動按照目標年化16%計算；',
  cardTwoDesc3: '月平均收益為1.2%，以下為示例：',
  cardTwoTableHeader1: '時間',
  cardTwoTableHeader2: '持有市值',
  cardTwoTableHeader3: '預計收益+獎勵',
  cardTwoTableRow1: '持有30天',
  cardTwoTableRow2: '持有60天',
  cardTwoTableRow3: '持有90天',
  cardTwoTableRow4: '總計',
  cardTwoTips: '註：預計收益僅供參考，投資組合的過往表現不能預示日後的表現；上述示例是按7.82港元兌1美元的匯率計算。',

  cardThreeTitle: '產品特色',
  cardThreeLabel1: '輕鬆取得活動獎勵+預期收益高達<span>HKD 4600</span>，實際保底獎勵金額收益高達年化<span>12%</span>',
  cardThreeLabel2: '享最佳匯率優勢，港幣兌美元匯率買賣價差不超過<span>0.05%</span>',
  cardThreeLabel3:
    '總資產大於30萬港幣的客戶，即可享最優惠的會員制度，不僅<span>港美股0佣金</span>，融資利率更是低至<span>5.6%</span>',

  cardFourTitle: '活動規則',
  cardFourLabel1:
    '此活動面向盈寶證券所有客戶，包括新客戶和老客戶，本活動不與任何其它投資組合活動疊加參與。過往獲得過投資組合獎勵的，不能再獲得獎勵。',
  cardFourLabel2:
    '活動所對應的獎勵金額，如滿足持有市值和時長，將在達標後發放相應的獎勵金額，獎勵金額將以現金券的形式發放給合資格的客戶。',
  cardFourLabel3:
    '本次活動產品為盈寶投資組合產品，需滿足最低持有時長30天，單次投資金額不低於5000美金，若客戶不採用定投方式，選擇一次性投入並滿足持有時長後，也可獲得投入資金所對應的獎勵。',
  cardFourLabel4:
    '如客戶中途轉出則代表客戶自願放棄活動獎勵，已發放的獎勵金額無需退還，未滿足實際持有天數的獎勵金額則視為客戶自動放棄活動資格。',

  pageTips:
    '免責聲明<br/>1、此活動由盈寶證券（香港）有限公司提供。本活動並非及不應被視為任何證券、金融產品或工具邀約、招攬、邀請、建議、意見或任何投資決策的建議，亦不應被視為專業意見。流覽本活動的人士在作出任何投資決定之前，應完全了解其風險以及相關法律，涉及的稅項及會計的特點及後果，並根據個人的情況決定投資是否適合個人的財政狀況及投資目標， 以及有關風險的承受能力，有需要時應尋找適當的專業意見。<br/>2、盈寶證券（香港）在法律規定範圍內保留更改此條款及細則，改或終止任何優惠的權利，而毋須另行通知。本活動的獎勵資格及資料確認，以盈寶證券（香港）後台資料為準，如有任何異議，盈寶證券（香港）保留最終解釋權及決定權。<br/>3、如對本次活動有任何查詢需要，請致電盈寶證券（香港）客服專線（852）23709538。'
}
