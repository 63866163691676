<template>
  <div class="wb-nav">
    <div class="nav-list" ref="navList">
      <span
        v-for="(item, index) in links"
        :class="['wb-nav-item', { selected: currentNav == index }]"
        :key="index"
        v-text="item.label"
        @click="goPage(item)"
      />
    </div>
    <div class="menu-icon" @click="toggleMenu">
      <wb-menu-btn :isOpen="isOpen" />
    </div>
    <div class="menu-panel" v-show="isOpen">
      <div class="padding-box" :class="{ open: delayOpen }" />
      <span
        v-for="(item, index) in links"
        :class="[
          'menu-item',
          { selected: currentNav == index }
        ]"
        :key="index"
        @click="goPage(item)"
      >
        {{ item.label }}
      </span>
      <a class="menu-item" href="javascript:;" key="onlineService" @click="onlineService">在线客服</a>
      <a :class="['menu-item', { selected: currentNav == 7 }]" @click.stop="toDownPage">{{
        $t('common.downLoadApp')
      }}</a>
      <a class="menu-item" style="display: flex; align-items: center; justify-content: space-between">
        <em>{{ $t('common.languageChoice') }}</em>
        <em class="lang-container">
          <i
            v-for="(item, index) in lang"
            :key="index"
            :class="{ selected: i18n.locale === item.type }"
            @click="changeLang(item.type)"
            >{{ item.name }}</i
          >
        </em>
      </a>
    </div>
  </div>
</template>

<script>
  import i18n from '@/language'
  import WbMenuBtn from '@/components/header/wb-menu-btn.vue'
  export default {
    name: 'wb-nav',

    components: {
      WbMenuBtn,
    },

    props: {
      // 是否是暗黑样式
      darkMode: {
        type: Boolean,
        default: false
      },
      currentPage: {}
    },
    data() {
      return {
        i18n: i18n,
        currentNav: 0,
        isOpen: false,
        delayOpen: false,
        showPopover: true,
        links: [
          {
            key: 'index',
            label: i18n.t('header.home'), // 首页
            url: './index.html'
          },
          {
            key: 'activity',
            label: i18n.t('header.active'), // 活动
            url: './activity.html'
          },
          // {
          //   key: 'fees',
          //   label: i18n.t('header.member'), // 会员
          //   url: './fees.html'
          // },
          {
            key: 'about',
            label: i18n.t('header.aboutUs'), // 关于我们
            url: './about.html'
          },
          {
            key: 'help',
            label: i18n.t('header.helpCenter'), // 帮助中心
            url: './help.html'
          },
          {
            key: 'join',
            label: i18n.t('header.joinUs'), // 加入盈宝
            url: './join.html'
          }
        ],
        lang: [{ type: 'zh_CN', name: i18n.t('header.zhCn') }, { type: 'zh_HK', name: i18n.t('header.zhHk') }],
        showActive: false,
        activeLen: 0,
        activeId: ''
      }
    },
    mounted() {
      this.initCurrentNav()
    },
    methods: {
      initCurrentNav() {
        const pathname = window.location.href
        let index = this.links.findIndex(item => {
          return pathname.includes(item.key)
        })
        if (index === -1) {
          index = this.links.findIndex(item => {
            return document.referrer.includes(item.key)
          })
        }
        // 判断是否下载页
        if (pathname.includes('down')) {
          this.currentNav = 7
        } else {
          this.currentNav = index < 0 ? 0 : index
        }
      },

      toggleMenu() {
        this.isOpen = !this.isOpen
        if (this.isOpen) {
          setTimeout(() => {
            this.delayOpen = true
          }, 200)
        } else {
          this.delayOpen = false
        }
        // 切换时判断是否有消息通知
        if (document.querySelector('.wb-notice-bar')) {
          document.querySelector('.page-head').style.top = window.innerWidth >= 768 ? (this.isOpen ? '0px' : '52px') : '0px' // 小于768px导航栏直接指定：768及以上尺寸在消息栏下
        }
        this.$emit('toggleMenu', this.isOpen)
      },

      // 在线客服
      onlineService() {
        this.isOpen = false
        window.open('https://099721.kefu.easemob.com/webim/im.html?configId=52c3eef3-a7db-4d6f-bf42-f8a58a05ccc6')
      },
      changeLang(lang) {
        if (i18n.locale === lang) return
        i18n.locale = lang
        window.location.reload()
      },
      toDownPage() {
        window.location.href = `./downMobile.html`
      },
      goPage(data) {
        window.location.href = data.url
      }
    },

  }
</script>

<style lang="scss" scoped>
 .wb-nav {
    margin: 0 auto;
    height: 100%;
    .nav-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      height: 100%;
    .wb-nav-item {
      position: relative;
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
      color: #333;
      cursor: pointer;
      margin-right: 40px;
      white-space: nowrap;
      user-select: none;
      &.selected {
        color: #0691ff;
        position: relative;
        &::after{
          content:'';
          position: absolute;
          left: 0;
          bottom: -3px;
          height: 3px;
          width: 100%;
          background-color: #0691ff;
          border-radius: 2px;
        }
      }

    }
  }

  .menu-icon {
    display: none;
  }

  .menu-panel {
    position: fixed;
    top: 54px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 32px;
    border-top: 1px solid #eee;
    background-color: #fff;
    font-size: 16px;
    font-weight: 600;
    z-index: 99999;
    overflow: auto;

    .padding-box {
      height: 40px;
      transition: height 0.4s;

      &.open {
        height: 0;
      }
    }

    .menu-item {
      display: block;
      width: 100%;
      height: 60px;
      line-height: 60px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      border-bottom: 0.5px solid #eeeeee;
      position: relative;

      &.selected {
        color: #0691ff;
      }
      &.has-active {
        position: relative;
        height: auto;
        transition: transform 1s linear;
        &.open::after,
        &.close::after {
          content: '';
          position: absolute;
          right: 0;
          top: 24px;
          width: 12px;
          height: 12px;
          background: url('../../assets/images/common/icon_aroow_drow.png') center/12px 12px no-repeat;
        }
        &.close::after {
          transform: rotateX(180deg);
        }
      }
      &.mobile-active{
        height: auto;
      }
    }
  }
}

@media (min-width:820px) and (max-width:1024px) {
  .wb-nav{
    .nav-list{
      .wb-nav-item{
        margin-right: 20px;
      }
    }
  }
}
//
@media screen and (max-width: 819px) {
  .wb-nav {
   margin: 0;
    .nav-list {
      display: none;
    }

    .menu-icon {
      display: block;
      height: 100%;
    }
  }

  em,
  i {
    font-style: normal;
    display: inline-block;
  }

  .lang-container {
    padding: 0 4px;
    line-height: 28px;
    height: 28px;
    text-align: center;
    background: #f9f9f9;
    border-radius: 4px;
    font-size: 14px;
    color: #999;
    i:not(.selected){
      margin: 0 16px;
    }
    .selected {
      width: 60px;
      text-align: center;
      line-height: 20px;
      background: #fff;
      border-radius: 3px;
      color: #0691ff;
      font-size: 14px;
    }
  }
}
@keyframes open {
  from {
    height: 60px;
  }
  to {
    height: 500px;
  }
}
</style>
