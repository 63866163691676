// 活动页面
module.exports = {
  'activity-rule': {
    title: '',
    requiredParams: {}
  },
  'activity-financing': {
    title: '',
    requiredParams: {}
  },
  'activity-stock-trans': {
    title: '',
    requiredParams: {}
  },
  'activity-stock-trans2': {
    title: '',
    requiredParams: {}
  },
  'activity-day-trade': {
    title: '',
    requiredParams: {}
  },
  'activity-double-festivals': {
    title: '', // 双旦活动
    requiredParams: {}
  },
  'activity-portfolio': {
    title: '', // 投资组合活动
    requiredParams: {}
  },
  'activity-portfolio2': {
    title: '', // 4-5月投资组合活动
    requiredParams: {}
  },
  'activity-portfolio-read': {
    title: '', // 投资组合新手必读
    requiredParams: {}
  },
  'activity-portfolio-fixed': {
    title: '', // 投资组合定投活动
    requiredParams: {}
  }
}
