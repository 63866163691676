/**
 * Copyright (C) 2021 盈宝信息科技（广州）有限公司 All Rights Reserved.
 */
import config from '@/config'

/**
 * 字符串版本号转数值版本
 * @param {String} version 字符串版本号，如1.6.0
 */
const versionStr2Num = version => {
  if (version) {
    return parseInt(version.split('.').join(''))
  } else {
    return 0
  }
}

/**
 * 在操作前检查app支持的最小版本
 * @param {String} minVersionStr app最低支持版本
 * @param {Function} handle 操作
 */
// const checkAppMinVersionBeforeHandle = ({ limitMinVersion, notPassHandle, passHandle }) => {
//   // 当不存在webview内，或者全局变量中没注册sdk则退出
//   if (!WBAppSdk.inApp) return false

//   if (WBAppSdk.checkAppMinVersion(limitMinVersion)) {
//     if (passHandle) {
//       passHandle()
//     }
//   } else {
//     if (notPassHandle) {
//       notPassHandle()
//     }
//   }
// }

/**
 * 通用app调用配置
 * @param {*} config 调用配置
 */
const callAppMethod = config => {
  // 当不存在webview内，或者全局变量中没注册sdk则退出
  if (!WBAppSdk.inApp || !window[WBAppSdk.name]) return false

  // 注册全局回调函数
  if (config.callback && typeof config.callback === 'function') {
    const callbackName = `__native_callback_${(Math.random() * 100000000).toFixed(0)}`

    const callback = config.callback.bind(config)
    window[callbackName] = function(args) {
      callback(args)
      delete window[callbackName]
    }

    config.callback = callbackName
  }

  window[WBAppSdk.name].postMessage(JSON.stringify(config))
}

class WBAppSdk {
  static name = 'WBAppSdk'
  static inApp = navigator.userAgent.includes('winBullApp') // 是否在盈宝app内打开
  static version = navigator.userAgent.split('|')[1] // app版本号
  static baseUrl = config.apiUrl

  // 检查是否满足app最小版本
  static checkAppMinVersion(limitMinVersion) {
    // 当不存在webview内，或者全局变量中没注册sdk则退出
    if (!WBAppSdk.inApp) return false
    const appVersion = versionStr2Num(WBAppSdk.version) || 0
    const minVersion = versionStr2Num(limitMinVersion) || 0
    return appVersion >= minVersion
  }

  /**
   * app退出webview
   * @param {Function|String} callback 回调函数
   */
  static exitWebView(callback) {
    return callAppMethod({ method: 'exitWebView', callback })
  }

  /**
   * 从H5接收图片并分享
   * @param {String} img base64图片
   * @param {Function|String} callback 回调函数
   */
  static acceptImgToShare(img, callback) {
    return callAppMethod({ method: 'acceptImgToShare', params: { img: img }, callback })
  }

  /**
   * 打开开户活动
   */
  static accountOpenActivityHandle() {
    return callAppMethod({ method: 'accountOpenActivityHandle' })
  }

  /**
   * 打开指定url
   */
  static openWebUrl(url, callback) {
    return callAppMethod({ method: 'openWebUrl', params: { url }, callback })
  }

  /**
   * 活动分享
   */
  static activityLinkShare(params) {
    return callAppMethod({ method: 'activityLinkShare', params })
  }

  /**
   * 打开交易首页
   */
  static gotoTradeHome() {
    return callAppMethod({ method: 'gotoTradeHome' })
  }

  /**
   * 打开入金
   */
  static toCashIn() {
    return callAppMethod({ method: 'toCashIn' })
  }

  /**
   * 通用活动处理
   */
  static activityCommonHandle(id) {
    return callAppMethod({ method: 'activityCommonHandle', params: { activityId: id } })
  }

  /**
   * 获取token
   */
  static getToken(callback) {
    return callAppMethod({ method: 'getToken', callback })
  }

  /**
   * 获取证券开户状态
   */
  static getAccountOpenStatus(callback) {
    return callAppMethod({ method: 'getAccountOpenStatus', callback })
  }

  /**
   * 调用开户操作
   */
  static callOpenAccountStatusPage() {
    return callAppMethod({ method: 'callOpenAccountStatusPage' })
  }

  /**
   * 调起登录
   */
  static toLogin() {
    return callAppMethod({ method: 'login' })
  }

  /**
   * 打开可融资股票列表页
   */
  static goFinancingStockList() {
    return callAppMethod({ method: 'goFinancingStockList' })
  }

  /**
   * 打开日内融首页
   */
  static checkAndGoDayTradeHome() {
    return callAppMethod({ method: 'checkAndGoDayTradeHome' })
  }

  /**
   * 打开帮助中心
   */
  static openHelpCenter() {
    return callAppMethod({ method: 'openHelpCenter' })
  }

  /**
   * 打开投资组合首页
   */
  static goPortfolioHome() {
    return callAppMethod({ method: 'goPortfolioHome' })
  }

  /**
   * 打开文章详情页
   */
  static gotoArticleDetail(detailId) {
    return callAppMethod({ method: 'gotoArticleDetail', params: { detailId: detailId } })
  }

  /**
   * 打开转仓页面
   */
  static goStockTrans(callback) {
    return callAppMethod({ method: 'goStockTrans', callback })
  }
}

export default WBAppSdk
