import { getAppInfo } from '@/api'
import dayjs from '@/utils/dayjs.js'

// app版本信息
class AppVersionInfo {
  static localKey = 'app_version_info'
  // 安卓版本
  static androidVersion = ''
  // ios版本
  static iosVersion = ''
  // 更新时间
  static updateTime = ''
  // 下载链接
  static iosLink = ''
  static androidLink = ''

  // 获取当前版本信息
  static async getCurrentAppInfo() {
    let androidInfo, iosInfo
    try {
      const { code, data } = await getAppInfo()
      if (code === 0) {
        androidInfo = data.find(item => item.platform === 'Android')
        iosInfo = data.find(item => item.platform === 'iOS')
      }
    } finally {
      this.genAppInfo(androidInfo, iosInfo)
    }
  }

  // 生成当前app版本信息
  static genAppInfo(androidInfo, iosInfo) {
    let androidUpdateTime, iosUpdateTime

    if (androidInfo) {
      this.androidVersion = androidInfo.version
      androidUpdateTime = androidInfo.upgradeTime
    } else {
      this.androidVersion = '-'
      androidUpdateTime = undefined
    }

    if (iosInfo) {
      this.iosVersion = iosInfo.version
      iosUpdateTime = iosInfo.upgradeTime
    } else {
      this.iosVersion = '-'
      iosUpdateTime = undefined
    }

    if (!androidUpdateTime && !iosUpdateTime) {
      this.updateTime = '-'
    } else {
      if (androidUpdateTime && !iosUpdateTime) {
        this.updateTime = androidUpdateTime
      }
      if (!androidUpdateTime && iosUpdateTime) {
        this.updateTime = iosUpdateTime
      }
      if (androidUpdateTime && iosUpdateTime) {
        this.updateTime = androidUpdateTime > iosUpdateTime ? androidUpdateTime : iosUpdateTime
      }

      if (this.updateTime) {
        this.updateTime = dayjs(this.updateTime).format('YYYY-MM-DD')
      } else {
        this.updateTime = '-'
      }
    }
    window.iosLink = iosInfo.apkUrl
    this.androidLink = androidInfo.apkUrl
  }
}

export default AppVersionInfo
