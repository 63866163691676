<template>
     <div class="content" v-html="contentHtml" />
</template>
<script>
  export default {
    name: 'text-content',
    props: {
      contentHtml: {
        type: String,
        default: ''
      }
    }
  }
</script>
<style lang="scss" scoped>
.content{
  min-height: 71vh;
  border-radius: 4px;
  line-height: 28px;
  color: #121212;
  font-size: 16px;
  text-align: left;
  word-break: break-all;
  white-space: pre-wrap;
  ::v-deep img{
    max-width: 700px;
    margin: 28px 0;
  }
}
@media screen and (max-width: 810px) {
  .content{
    width: 100%;
    padding: 24px 0 40px;
    background-color: #fff;
    margin: 0;
    ::v-deep img{
      max-width: 100%;
    }
  }

}

</style>
