<template>
  <div class="market-preview-table">
    <div class="table-header row border-b">
      <!-- 组合名称 -->
      <div class="fixed-col center-txt header-txt bg1 border-r border-tl-radius">
        {{ $t('activityPortfolio2.cardTwoTableHeader1') }}
      </div>
      <!-- 盈宝稳健收益宝 -->
      <div class="avg-col center-txt header-txt bg1 border-r" v-html="$t('activityPortfolio2.cardTwoTableHeader2')" />
      <!-- 盈宝全球ETF收益增强 -->
      <div class="avg-col center-txt header-txt bg1 border-r" v-html="$t('activityPortfolio2.cardTwoTableHeader3')" />
      <!-- 盈宝全球精选ETF轮动 -->
      <div
        class="avg-col center-txt header-txt bg1 border-tr-radius"
        v-html="$t('activityPortfolio2.cardTwoTableHeader4')"
      />
    </div>

    <div class="table-row row border-b">
      <!-- 年化收益率 -->
      <div
        class="fixed-col center-txt cell-txt bg2 border-r"
        v-html="$t('activityPortfolio2.annualized') + '<br />' + $t('activityPortfolio2.cardTwoTableLabel1')"
      />
      <div class="avg-col center-txt cell-txt bg3 border-r">8.87%</div>
      <div class="avg-col center-txt cell-txt bg3 border-r">15.19%</div>
      <div class="avg-col center-txt cell-txt bg3">21.56%</div>
    </div>

    <div class="table-row row border-b">
      <!-- 最大回撤率 -->
      <div
        class="fixed-col center-txt cell-txt bg2 border-r"
        v-html="$t('activityPortfolio2.maximum') + '<br />' + $t('activityPortfolio2.cardTwoTableLabel2')"
      />
      <div class="avg-col center-txt cell-txt bg3 border-r">-2.25%</div>
      <div class="avg-col center-txt cell-txt bg3 border-r">-4.68%</div>
      <div class="avg-col center-txt cell-txt bg3">-7.54%</div>
    </div>

    <div class="table-mult-row row">
      <div class="fixed-col row">
        <!-- 美国市场 -->
        <div class="left-col center-txt cell-txt bg4 border-b">{{ $t('activityPortfolio2.cardTwoTableLabel3') }}</div>
        <div class="right-col col">
          <!-- 收益率 -->
          <div class="t-cell center-txt cell-txt bg5 border-r border-b">
            {{ $t('activityPortfolio2.cardTwoTableLabel1') }}
          </div>
          <!-- 回撤率 -->
          <div class="b-cell center-txt cell-txt bg5 border-r border-b">
            {{ $t('activityPortfolio2.cardTwoTableLabel2') }}
          </div>
        </div>
      </div>
      <div class="avg-col col">
        <div
          class="t-cell center-txt-v cell-txt bg6 border-r border-b"
          v-html="'<span>> 84%</span><span>' + $t('activityPortfolio2.cardTwoTableValue1') + '</span>'"
        />
        <div
          class="b-cell center-txt-v cell-txt bg6 border-r border-b"
          v-html="'<span>< 79%</span><span>' + $t('activityPortfolio2.cardTwoTableValue1') + '</span>'"
        />
      </div>
      <div class="avg-col col">
        <div
          class="t-cell center-txt-v cell-txt bg6 border-r border-b"
          v-html="'<span>> 82%</span><span>' + $t('activityPortfolio2.cardTwoTableValue2') + '</span>'"
        />
        <div
          class="b-cell center-txt-v cell-txt bg6 border-r border-b"
          v-html="'<span>< 93%</span><span>' + $t('activityPortfolio2.cardTwoTableValue2') + '</span>'"
        />
      </div>
      <div class="avg-col col">
        <div
          class="t-cell center-txt-v cell-txt bg6 border-b"
          v-html="'<span>> 75%</span><span>' + $t('activityPortfolio2.cardTwoTableValue3') + '</span>'"
        />
        <div
          class="b-cell center-txt-v cell-txt bg6 border-b"
          v-html="'<span>< 95%</span><span>' + $t('activityPortfolio2.cardTwoTableValue3') + '</span>'"
        />
      </div>
    </div>

    <div class="table-mult-row row">
      <div class="fixed-col row">
        <!-- 香港市场 -->
        <div class="left-col center-txt cell-txt bg7">{{ $t('activityPortfolio2.cardTwoTableLabel4') }}</div>
        <div class="right-col col">
          <!-- 收益率 -->
          <div class="t-cell center-txt cell-txt bg8 border-r border-b">
            {{ $t('activityPortfolio2.cardTwoTableLabel1') }}
          </div>
          <!-- 回撤率 -->
          <div class="b-cell center-txt cell-txt bg8 border-r">{{ $t('activityPortfolio2.cardTwoTableLabel2') }}</div>
        </div>
      </div>
      <div class="avg-col col">
        <div
          class="t-cell center-txt-v cell-txt bg9 border-r border-b"
          v-html="'<span>> 83%</span><span>' + $t('activityPortfolio2.cardTwoTableValue1') + '</span>'"
        />
        <div
          class="b-cell center-txt-v cell-txt bg9 border-r"
          v-html="'<span>< 86%</span><span>' + $t('activityPortfolio2.cardTwoTableValue1') + '</span>'"
        />
      </div>
      <div class="avg-col col">
        <div
          class="t-cell center-txt-v cell-txt bg9 border-r border-b"
          v-html="'<span>> 93%</span><span>' + $t('activityPortfolio2.cardTwoTableValue2') + '</span>'"
        />
        <div
          class="b-cell center-txt-v cell-txt bg9 border-r"
          v-html="'<span>< 90%</span><span>' + $t('activityPortfolio2.cardTwoTableValue2') + '</span>'"
        />
      </div>
      <div class="avg-col col">
        <div
          class="t-cell center-txt-v cell-txt bg9 border-b"
          v-html="'<span>> 83%</span><span>' + $t('activityPortfolio2.cardTwoTableValue3') + '</span>'"
        />
        <div
          class="b-cell center-txt-v cell-txt bg9"
          v-html="'<span>< 86%</span><span>' + $t('activityPortfolio2.cardTwoTableValue3') + '</span>'"
        />
      </div>
    </div>

    <div class="tips-wrap">
      <div class="def-tips">{{ $t('activityPortfolio2.cardTwoTips1') }}</div>
      <div class="other-tips" v-if="otherTips">{{ otherTips }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'market-preview-table',

    props: ['otherTips']
  }
</script>

<style lang="scss" scoped>
.market-preview-table {
  .bg1 {
    background: #5489ff;
  }
  .bg2 {
    background: rgba(84, 137, 255, 0.15);
  }
  .bg3 {
    background: rgba(84, 137, 255, 0.1);
  }
  .bg4 {
    background: rgba(39, 167, 255, 0.3);
  }
  .bg5 {
    background: rgba(39, 167, 255, 0.2);
  }
  .bg6 {
    background: rgba(39, 167, 255, 0.1);
  }
  .bg7 {
    background: rgba(158, 84, 255, 0.3);
  }
  .bg8 {
    background: rgba(158, 84, 255, 0.2);
  }
  .bg9 {
    background: rgba(158, 84, 255, 0.1);
  }
  .center-txt {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3px;
    text-align: center;
  }
  .center-txt-v {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .header-txt {
    font-size: 13px;
    font-weight: bold;
    line-height: 18px;
    color: #ffffff;
  }
  .cell-txt {
    font-size: 13px;
    line-height: 18px;
    color: #3d3d3d;
  }

  .border-r {
    border-right: 1px solid #fff;
  }
  .border-b {
    border-bottom: 1px solid #fff;
  }
  .border-tl-radius {
    border-top-left-radius: 8px;
  }
  .border-tr-radius {
    border-top-right-radius: 8px;
  }

  .row,
  .col {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .col {
    flex-direction: column;
  }

  .fixed-col {
    width: 70px;
    min-height: 56px;
  }

  .avg-col {
    width: calc((100% - 70px) / 3);
    min-height: 56px;
  }

  .left-col,
  .right-col {
    width: 50%;
    height: 116px;
  }

  .left-col {
    writing-mode: vertical-lr;
    letter-spacing: 3px;
  }
  .right-col .center-txt {
    writing-mode: vertical-lr;
    letter-spacing: 2px;
    padding-top: 2px;
  }

  .t-cell,
  .b-cell {
    width: 100%;
    height: 58px;
  }

  .tips-wrap {
    background: #f9f9f9;
    padding: 12px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .def-tips {
      font-size: 11px;
      line-height: 16px;
      color: #777777;
    }

    .other-tips {
      font-size: 11px;
      line-height: 16px;
      color: #999999;
      padding-top: 4px;
    }
  }
}
</style>
