<template>
  <van-popup
    :value="visible"
    :lock-scroll="true"
    :overlay-style="{ background: 'rgba(0, 0, 0, 0.6);' }"
    @click-overlay="close"
  >
    <div class="outside-app-tips">
      <div class="tips-content">
        <!-- 进入盈宝证券APP -->
        <div class="tips-title">{{ $t('activitys.goInApp') }}</div>
        <!-- 查看活动详情 -->
        <div class="tips-desc">{{ $t('activitys.checkActivityDetail') }}</div>
        <div class="tips-btn" @click="close">{{ $t('activitys.knowIt') }}</div>
      </div>
      <img class="close-icon" src="@/assets/images/activitys/icon_close_big.webp" @click="close" />
    </div>
  </van-popup>
</template>

<script>
  export default {
    name: 'outside-app-tips',

    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },

    computed: {},

    methods: {
      close() {
        this.$emit('update:visible', false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .van-popup {
    background-color: transparent;
  }
  .outside-app-tips {
    width: 279px;
    height: 290px;
    background: transparent;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .tips-content {
      width: 279px;
      height: 200px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url('../../assets/images/activitys/outside_app_bg.webp');
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .tips-title {
        padding-top: 47px;
        padding-left: 70px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #043467;
      }

      .tips-desc {
        padding-top: 6px;
        padding-left: 18px;
        font-size: 18px;
        line-height: 24px;
        color: #043467;
      }

      .tips-btn {
        margin-top: 35px;
        width: 196px;
        height: 40px;
        border-radius: 20px;
        background: linear-gradient(180deg, #1999ff 0%, #62baff 144%);
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
    }

    .close-icon {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
</style>
