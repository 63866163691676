import activityFinancing from './data/activity-financing-hk'
import activityStockTrans from './data/activity-stock-trans-hk'
import activityStockTrans2 from './data/activity-stock-trans2-hk'
import activityDayTrade from './data/activity-day-trade-hk'
import activityDoubleFestivals from './data/activity-double-festivals-hk'
import activityPortfolio from './data/activity-portfolio-hk'
import activityPortfolio2 from './data/activity-portfolio2-hk'
import activityPortfolioRead from './data/activity-portfolio-read-hk'
import activityPortfolioFixed from './data/activity-portfolio-fixed-hk'

export default {
  // 通用翻译
  common: {
    confirm: '確定',
    return: '返回',
    cancel: '取消',
    noData: '暫無數據',
    reminder: '溫馨提示',
    defaultInputHolder: '請輸入',
    defaultSelectHolder: '請選擇',
    copySuccess: '複製成功',
    copyNotSupport: '當前設備不支持複製',
    loadMore: '加載更多',
    noMore: '暫無更多',
    open: '打開',
    reqError: '請求失敗，請稍後重試',
    stayTuned: '敬請期待',
    commonUse: '常用',
    goAppHandle: '請前往app進行操作',
    placeholder: '請輸入搜索關鍵詞',
    relation: '相關',
    result: '結果',
    count: '條',
    suggest: '推薦',
    relief:
      '免責聲明：上述內容僅為發佈者個人觀點，不構成盈寶或本社區的任何投資建議，盈寶或本社區不承擔因上述發佈者言論引起的任何損失和損害。',
    joinTitle: '令您投資Sure Win',
    situation: '崗位',
    noAccount: '暫無崗位信息',
    publishTime: '發佈時間',
    scanCodeTips: '掃碼下載APP',
    noSearch: '抱歉，未找到相關信息',
    questionModule: '問題分類',
    downLoadApp: '下載APP',
    languageChoice: '語言選擇',
    downLoadText: '下載',
    downloadAppDesc: '盈寶自營投資組合低回撤高收益，全球ETF資產配置',
    noActive: '活動即將上線，敬請期待',
    noCost: '暫無收費信息',
    weixinDownloadTips: '請點擊右上角按鈕，選擇在瀏覽器中打開',
    openDownloadTips1: '請點擊右上角',
    openDownloadTips2: '選擇瀏覽器打開',
    contact: '立即咨詢',
    knowMore: '了解更多',
    activeDetail: '活動詳情',
    appUpdateTips: '請更新APP最新版本後體驗~'
  },

  // 地區
  location: {
    motherCountry: '中國',
    hongKong: '香港',
    maCao: '澳門',
    taiWan: '台灣'
  },

  router: {
    winbull: '盈寶證券官網',
    winbullSite: '盈寶證券官網-盈寶自營投資組合低回撤高收益，全球ETF資產配置',
    fees: '收費',
    member: '會員',
    active: '活動',
    aboutUs: '關於我們',
    helpCenter: '幫助中心',
    newsDetail: '資訊詳情',
    notice: '消息通知',
    searchResult: '搜索結果',
    joinUs: '加入盈寶',
    jobDetail: '崗位詳情',
    downLoadApp: '下載APP',
    questionModule: '問題模塊'
  },

  header: {
    home: '首頁',
    fees: '收費',
    member: '會員',
    active: '活動',
    aboutUs: '關於我們',
    helpCenter: '幫助中心',
    joinUs: '加入盈寶',
    zhCn: '簡體',
    zhHk: '繁體',
    question: ''
  },

  footer: {
    connectUs: '聯繫我們',
    consumerHotline: '客服電話',
    other: '其他',
    hongKong: '香港',
    officeHours: '辦公時間',
    tradingDay: '交易日',
    followUs: '關注我們',
    appDeveloper: 'APP開發者',
    company: '盈寶證券國際(⾹港)有限公司',
    appName: '應⽤名稱',
    winbull: '盈寶證券',
    appVersion: '應⽤版本',
    ios: 'iOS',
    android: '安卓',
    permission: '权限说明',
    privacyAgreement: '隱私權限',
    serviceRule: '⽤戶權限',
    updateTime: '更新時間',
    warning: '市場有風險，投資需謹慎',
    email: '電郵查詢',
    fwtk: '服務條款',
    khxy: '客戶協議',
    mzsm: '免責聲明',
    wlaq: '網絡安全',
    yszc: '私隱政策及個人資料收集聲明',
    commonService: '一般查詢',
    businessService: '商務查詢'
  },

  home: {
    banner: {
      title: '一站式',
      title2: '智能化投資平台',
      desc: '全球投資隨心所欲，助力您的財富增值'
    },
    specialTitle: '我們的特色',
    ourSpecial: {
      title: '高CP值交易系統',
      subTitle: '自研一站式交易平台',
      title2: '經紀人',
      subTitle2: '全港獨家經紀人客戶配對平台',
      title3: '極低收費',
      subTitle3: '交易成本,無感體驗'
    },
    choiceTitle: '為什麼選擇盈寶',
    choiceReason: {
      title: '合規持牌',
      desc: '香港證監會認可的持牌',
      desc2: '證券經紀商',
      title2: '安全保障',
      desc3: '客戶資產獨立托管於銀行',
      desc4: '受香港投資者賠償基金(ICF)保障',
      title3: '全方位支援',
      desc5: '專業，迅速，有溫度的客戶服務'
    }
  },

  aboutUs: {
    overview: '公司概況',
    overviewDesc:
      '盈寶證券國際（香港）有限公司總部位於香港，致力於打造成新一代互聯網券商，為全球投資者提供安全、便捷、高效的國際投資服務。旗下盈寶證券APP於2022年3月發佈並投入運營，為全球投資者提供港美股的實時行情、證券交易與及財富管理服務。公司並已獲得IDG資本的投資意向，我們正處於發展階段，發展空間與機會潛力無限。',

    overviewDescVip:
      '我們目前已在⾹港設立公司，致力於打造成新一代互聯網券商，為全球投資者提供安全、便捷、高效的國際投資服務。旗下盈寶證券APP於2022年3月發佈並投入運營，為投資者提供港股、美股的實時行情、證券交易與財富管理服務。公司已獲得IDG資本，我們正處於發展階段，發展空間與機會潛力無限，現誠邀您的加盟。',

    overviewItemTitle1: '願景',
    overviewItemDesc1: '為全球用户提供高效證券投資服務',
    overviewItemTitle2: '價值觀',
    overviewItemDesc2: '誠實  判斷力  溝通力  影響力  自驅力  好奇心',

    prospects: '發展前景',
    prospectsDesc1: {
      desc: '為香港證監會持牌法團',
      desc1: '公司為香港證監會持牌法團',
      b: '(持牌編號：BRG131)',
      desc2:
        '，現持有證券交易牌照（第1類）、就證券提供意見牌照（第4類）、提供資產管理牌照（第9類）等業務牌照資格及香港聯合交易所',
      b2: '(經紀編號: 3011)',
      desc3:
        '。後續將會申辦新加坡等金融中心之交易會員資格。我們亦將計劃推出英文以及其他語言版本證券APP，為全球投資者提供高效投資及管理服務。'
    },
    prospectsDesc2: '公司現正實行創業團隊股權激勵計劃及合夥人計劃，以吸引更多的人才加盟。',

    team: '管理及團隊',
    teamDesc:
      '公司創始人朱彪，同時亦為球探網聯合創始人，項目已啓動上市計劃。我們的團隊擁有豐富的券商以及互聯網產品運營經驗、卓越的產品研發和創新能力。',

    environment: '公司環境',

    address: '公司地址',
    addressItemTitle1: '盈寶證券國際(⾹港)有限公司',
    addressItemDesc1: '地址：香港金鐘道95號統一中心26樓A室',
    addressItemTitle2: '盈寶信息服務(深圳)有限公司',
    addressItemDesc2: '地址：深圳福田區博今商務廣場1908單元',
    addressItemTitle3: '盈寶信息科技(⼴州)有限公司',
    addressItemDesc3: '地址：天河區珠江新城天盈廣場3102室'
  },

  joinUs: {
    title: '期待你加入盈寶證券團隊 - 正直 進取 共贏',
    desc1:
      '盈寶是“新一代”互聯網券商，總部設於香港。盈寶的業務範圍幅射大灣區, 網絡全球, 爲全球投資者提供安全高效的投資服務、證券交易及財富管理服務。盈寶作爲初創互聯網券商, 與內地以至東盟國家緊密連繫, 盡享龐大商機及拓展東南亞的機遇。 盈寶(香港)爲客戶提供一個全面化的APP, 方便用戶隨時隨地無縫進行投資。',
    desc2:
      '盈寶證券於2022年3月發佈並投入運營, 集團的團隊具有豐富的券商及互聯網運營經驗，擁有優秀的産品研發和創新能力。爲客戶提供A股、港股、美股的實時行情、證券交易、專業的財富管理服務。',
    desc3:
      '盈寶爲香港證監會持牌法團（持牌編號：BRG131），持有1、4、9號業務牌照資格。爲香港證監會核准持牌中介人，獲准在香港從事第一類（證券交易）、第四類（就證券提供意見）及第九類（提供資產管理）的受規管活動，亦爲香港聯合交易所交易參與者。',
    desc4:
      '我們充分發揮企業優勢，投資潛力産業，提高經營效益，提升品牌價值，爲投資者創造良好的投資回報。敢於創新，挖掘潛力，精益求精，提供優質産品及專業服務，不斷提升競爭力。'
  },

  login: {
    signIn: '登錄',
    signUp: '註冊',
    enterPhone: '請輸入手機號',
    pleaseEnterCorrectPhone: '請輸入正確的手機號',
    enterVcode: '輸入驗證碼',
    pleaseEnterVcode: '請輸入驗證碼',
    sendVcode: '發送驗證碼',
    reSendVcode: '重新發送驗證碼',
    countdown: '倒計時',
    pleasePickUserStatement: '請勾選用戶協議',
    firstSignWillRegister: '首次登錄將自動註冊賬號',
    iAgreed: '我已同意',
    serviceAgreement: '服務協議',
    registrationAgreement: '註冊協議',
    and: '及',
    privacyAgreement: '隱私協議',

    userAccountLocked: '賬號已鎖定',
    pleaseCall: '請致電',
    serviceHotline: '服務熱線',
    hongkongHotline: '香港',
    otherHotline: '其他',
    signInSuccess: '登錄成功',
    registerChannelValid: '原有渠道已失效，點擊跳轉至官方渠道',

    captchaTitle: '請完成安全驗證後繼續：',
    captchaTips: '按住左邊按鈕拖動完成上方拼圖',
    captchaVerifySuccess: '驗證成功',
    captchaVerifyFail: '驗證失敗，請重試',
    captchaOutTime: '驗證碼已過期，請重試'
  },

  activitys: {
    activity: '活動',
    time: '時間',
    activityRule: '活動規則',
    activityRule2: '規則說明',
    activityTime: '活動時間',
    joinActivity: '參與活動',
    joinActivity2: '參加活動',
    immediatelyOpen: '立即開戶',
    immediatelyCashIn: '立即入金',
    immediatelyJoin: '立即參與',
    goLogin: '去登入',
    goOpenAccount: '去開戶',
    goTrade: '去交易',
    activityNoReady: '活動未開始',
    activityIsEnd: '活動已結束',
    goInApp: '進入盈寶證券APP',
    checkActivityDetail: '查看活動詳情',
    knowIt: '已了解',
    pleaseUpdateApp: '請更新APP最新版本後體驗',
    activityStatement: '本活動最終解釋權歸盈寶證券所有'
  },

  activityFinancing: activityFinancing,

  activityStockTrans: activityStockTrans,
  activityStockTrans2: activityStockTrans2,

  activityDayTrade: activityDayTrade,

  activityDoubleFestivals: activityDoubleFestivals,

  activityPortfolio: activityPortfolio,
  activityPortfolio2: activityPortfolio2,

  activityPortfolioRead: activityPortfolioRead,

  activityPortfolioFixed: activityPortfolioFixed,
}
