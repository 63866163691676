<template>
  <div class="limit-wrap">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'limit-wrap'
  }
</script>

<style lang="scss" scoped>
  .limit-wrap {
    position: relative;
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
  }

  @media (min-width: 750px) and (max-width: 1024px) {
    .limit-wrap {
      max-width: 800px;
    }
  }

  @media screen and (max-width: 750px) {
    .limit-wrap {
      max-width: 100%;
      padding: 0 16px;
    }
  }
</style>
