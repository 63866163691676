<template>
  <div class="wb-header">
    <div class="wb-header-content">
      <div class="logo">
        <wb-header-logo/>
      </div>
      <div class="content">
        <wb-nav @toggleMenu="toggleMenu" />
      </div>
      <wb-lang />
    </div>
  </div>
</template>

<script>
  import WbHeaderLogo from '@/components/header/wb-header-logo.vue'
  import WbNav from '@/components/header/wb-nav.vue'
  import wbLang from '@/components/header/wb-lang'

  export default {
    name: 'wb-header',
    components: {
      WbHeaderLogo,
      WbNav,
      wbLang
    },

    props: {
      // 是否是暗黑样式
      darkMode: {
        type: Boolean,
        default: false
      },
      currentPage: {}
    },

    data() {
      return {
        isOpenMenu: false
      }
    },

    // computed: {
    //   isDarkMode() {
    //     if (this.isOpenMenu) {
    //       return false
    //     } else {
    //       return this.darkMode
    //     }
    //   }
    // },

    methods: {
      toggleMenu(flag) {
        this.isOpenMenu = flag
      }
    }
  }
</script>

<style lang="scss" scoped>
.wb-header {
  width: 100%;
  height: 80px;
  margin: 0 auto;
  background: #fff;
  padding-left: 80px;
  padding-right: 80px;
}

.wb-header-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .content {
    flex: 1;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}
@media screen and (max-width:767px) {
  .wb-header{
    height: 56px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .wb-header-content {
    .content{
      justify-content: flex-end;
    }
  }
}
@media (min-width:768px) and (max-width:819px) {
  .wb-header{
    height: 64px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .wb-header-content {
    .content{
      justify-content: flex-end;
    }
  }
}
@media (min-width:820px) and (max-width:1024px) {
  .wb-header{
    height: 64px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
</style>
