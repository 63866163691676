// activityStockTrans2
export default {
  immediatelyOpen: '立即開戶',
  activityRule: '活動規則',
  activityTime: '活動時間',

  routerTitle: '轉倉活動',
  shareTitle: '轉倉去盈寶有補貼呢~ 最高 HK$3800 補助！',
  shareDesc: '新舊客戶專享福利, 快搶雙重禮遇!',

  joinActivity: '馬上轉倉',
  accumulatedAmount: '累計淨轉倉金額(或等值)',
  reward: '獎勵',
  futeStock: '福特',
  stockUnit: '股',
  orAbove: '或以上',
  give: '送',

  cardOneTitle: '新客戶首次轉倉最高贈送<span>HKD2800</span>禮包',
  cardOneOther: '再額外報銷轉倉費用最高500港元或100美元',
  cardOneTips:
    '註：新客戶是指首次轉倉且在先前註冊未有入金且未轉倉的客戶。獲得獎勵需維持30天不出金且股票交易大於或等於一次。',

  cardTwoTitle: '老客戶轉倉最高贈送<span>HKD1800</span>禮包',
  cardTwoOther: '再額外報銷轉倉費用最高500港元或100美元',
  cardTwoTips: '註：老客戶是指在盈寶已入金或已轉倉客戶。<br/>獲得獎勵需維持30天不出金且股票交易大於等於一次。',

  cardThreeTitle: '如何轉倉到盈寶',
  cardThreeText1: '選擇轉出券商',
  cardThreeText2: '填寫股票資訊',
  cardThreeText3: '聯絡對方券商',
  cardThreeTipsLabel: '具體操作指引',
  cardThreeTips2: '港股轉入說明',
  cardThreeTips3: '美股轉入說明',

  cardFourTitle: '為什麼選擇盈寶',
  cardFourIntroTitle1: '持牌券商（編號：BRG131）',
  cardFourIntroDesc1: '獲認可的網路券商，擁有1、4、9牌照',
  cardFourIntroTitle2: '快人一步',
  cardFourIntroDesc2: '毫秒級下單速度，第一時間掌握交易先機',
  cardFourIntroTitle3: '一站式交易',
  cardFourIntroDesc3: '支持港股、美股、基金等投資標的',
  cardFourIntroTitle4: '極致客戶服務',
  cardFourIntroDesc4: '7x24小時線上客服，一對一解答問題',

  pageTips:
    '註：轉倉報銷費用以實際產生的為準，需提供扣費憑證給線上客服登記或發郵件登記（services@winbull.hk），需在成功轉倉後的14個工作天內完成，若超過時間未申請將享有不了報銷補助。 '
}
