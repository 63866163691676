let linkBaseUrl

switch (process.env.VUE_APP_ENV) {
  case 'dev':
    linkBaseUrl = 'http://dev.winbull.cc:9080'
    break
  case 'sit':
    linkBaseUrl = 'https://sit.winbull.cc:10443'
    break
  case 'test':
    linkBaseUrl = 'https://uat.winbull.cc'
    break
  case 'prod':
    linkBaseUrl = 'https://api.winbull8.com'
    break
  default:
    linkBaseUrl = 'http://dev.winbull.cc:9080'
    break
}

const linkDict = {
  helpDetailUrl: linkBaseUrl + '/h5/help/detail.html',
  // 服务协议
  provisionStatementUrl: linkBaseUrl + '/h5/help/provision-statement.html',

  // 隐私政策
  privacyPolicyUrl: linkBaseUrl + '/h5/help/privacy-policy.html'
}

export default linkDict
