<template>
  <div class="market-preview-table">
    <div class="table-header row border-b">
      <!-- 组合名称 -->
      <div class="fixed-col center-txt header-txt bg1 border-r border-tl-radius">
        {{ $t('activityPortfolio.cardTwoTableHeader1') }}
      </div>
      <!-- 盈宝稳健收益宝 -->
      <div class="avg-col center-txt header-txt bg1 border-r">{{ $t('activityPortfolio.cardTwoTableHeader2') }}</div>
      <!-- 盈宝全球ETF收益增强 -->
      <div class="avg-col center-txt header-txt bg1 border-r">{{ $t('activityPortfolio.cardTwoTableHeader3') }}</div>
      <!-- 盈宝全球精选ETF轮动 -->
      <div class="avg-col center-txt header-txt bg1 border-tr-radius">
        {{ $t('activityPortfolio.cardTwoTableHeader4') }}
      </div>
    </div>

    <div class="table-row row border-b">
      <!-- 收益率 -->
      <div class="fixed-col center-txt cell-txt bg2 border-r">{{ $t('activityPortfolio.cardTwoTableLabel1') }}</div>
      <div class="avg-col center-txt cell-txt bg3 border-r">8.06%</div>
      <div class="avg-col center-txt cell-txt bg3 border-r">13.68%</div>
      <div class="avg-col center-txt cell-txt bg3">18.13%</div>
    </div>

    <div class="table-row row border-b">
      <!-- 回撤率 -->
      <div class="fixed-col center-txt cell-txt bg2 border-r">{{ $t('activityPortfolio.cardTwoTableLabel2') }}</div>
      <div class="avg-col center-txt cell-txt bg3 border-r">-2.25%</div>
      <div class="avg-col center-txt cell-txt bg3 border-r">-4.68%</div>
      <div class="avg-col center-txt cell-txt bg3">-7.54%</div>
    </div>

    <div class="table-mult-row row">
      <div class="fixed-col row">
        <!-- 美国市场 -->
        <div class="left-col center-txt cell-txt bg4 border-b">{{ $t('activityPortfolio.cardTwoTableLabel3') }}</div>
        <div class="right-col col">
          <!-- 收益率 -->
          <div class="t-cell center-txt cell-txt bg5 border-r border-b">
            {{ $t('activityPortfolio.cardTwoTableLabel1') }}
          </div>
          <!-- 回撤率 -->
          <div class="b-cell center-txt cell-txt bg5 border-r border-b">
            {{ $t('activityPortfolio.cardTwoTableLabel2') }}
          </div>
        </div>
      </div>
      <div class="avg-col col">
        <div
          class="t-cell center-txt-v cell-txt bg6 border-r border-b"
          v-html="'<span>> 80%</span><span>' + $t('activityPortfolio.cardTwoTableValue1') + '</span>'"
        />
        <div
          class="b-cell center-txt-v cell-txt bg6 border-r border-b"
          v-html="'<span>< 80%</span><span>' + $t('activityPortfolio.cardTwoTableValue1') + '</span>'"
        />
      </div>
      <div class="avg-col col">
        <div
          class="t-cell center-txt-v cell-txt bg6 border-r border-b"
          v-html="'<span>> 81%</span><span>' + $t('activityPortfolio.cardTwoTableValue2') + '</span>'"
        />
        <div
          class="b-cell center-txt-v cell-txt bg6 border-r border-b"
          v-html="'<span>< 93%</span><span>' + $t('activityPortfolio.cardTwoTableValue2') + '</span>'"
        />
      </div>
      <div class="avg-col col">
        <div
          class="t-cell center-txt-v cell-txt bg6 border-b"
          v-html="'<span>> 78%</span><span>' + $t('activityPortfolio.cardTwoTableValue3') + '</span>'"
        />
        <div
          class="b-cell center-txt-v cell-txt bg6 border-b"
          v-html="'<span>< 95%</span><span>' + $t('activityPortfolio.cardTwoTableValue3') + '</span>'"
        />
      </div>
    </div>

    <div class="table-mult-row row">
      <div class="fixed-col row">
        <!-- 香港市场 -->
        <div class="left-col center-txt cell-txt bg7">{{ $t('activityPortfolio.cardTwoTableLabel4') }}</div>
        <div class="right-col col">
          <!-- 收益率 -->
          <div class="t-cell center-txt cell-txt bg8 border-r border-b">
            {{ $t('activityPortfolio.cardTwoTableLabel1') }}
          </div>
          <!-- 回撤率 -->
          <div class="b-cell center-txt cell-txt bg8 border-r">{{ $t('activityPortfolio.cardTwoTableLabel2') }}</div>
        </div>
      </div>
      <div class="avg-col col">
        <div
          class="t-cell center-txt-v cell-txt bg9 border-r border-b"
          v-html="'<span>> 81%</span><span>' + $t('activityPortfolio.cardTwoTableValue1') + '</span>'"
        />
        <div
          class="b-cell center-txt-v cell-txt bg9 border-r"
          v-html="'<span>< 87%</span><span>' + $t('activityPortfolio.cardTwoTableValue1') + '</span>'"
        />
      </div>
      <div class="avg-col col">
        <div
          class="t-cell center-txt-v cell-txt bg9 border-r border-b"
          v-html="'<span>> 91%</span><span>' + $t('activityPortfolio.cardTwoTableValue2') + '</span>'"
        />
        <div
          class="b-cell center-txt-v cell-txt bg9 border-r"
          v-html="'<span>< 90%</span><span>' + $t('activityPortfolio.cardTwoTableValue2') + '</span>'"
        />
      </div>
      <div class="avg-col col">
        <div
          class="t-cell center-txt-v cell-txt bg9 border-b"
          v-html="'<span>> 83%</span><span>' + $t('activityPortfolio.cardTwoTableValue3') + '</span>'"
        />
        <div
          class="b-cell center-txt-v cell-txt bg9"
          v-html="'<span>< 85%</span><span>' + $t('activityPortfolio.cardTwoTableValue3') + '</span>'"
        />
      </div>
    </div>

    <div class="tips-wrap">
      <div class="def-tips">{{ $t('activityPortfolio.cardTwoTips1') }}</div>
      <div class="other-tips" v-if="otherTips">{{ otherTips }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'market-preview-table',

    props: ['otherTips']
  }
</script>

<style lang="scss" scoped>
  .market-preview-table {
    .bg1 {
      background: #5489ff;
    }
    .bg2 {
      background: rgba(84, 137, 255, 0.15);
    }
    .bg3 {
      background: rgba(84, 137, 255, 0.1);
    }
    .bg4 {
      background: rgba(39, 167, 255, 0.3);
    }
    .bg5 {
      background: rgba(39, 167, 255, 0.2);
    }
    .bg6 {
      background: rgba(39, 167, 255, 0.1);
    }
    .bg7 {
      background: rgba(158, 84, 255, 0.3);
    }
    .bg8 {
      background: rgba(158, 84, 255, 0.2);
    }
    .bg9 {
      background: rgba(158, 84, 255, 0.1);
    }
    .center-txt {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 3px;
      text-align: center;
    }
    .center-txt-v {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .header-txt {
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      color: #ffffff;
    }
    .cell-txt {
      font-size: 14px;
      line-height: 18px;
      color: #3d3d3d;
    }

    .border-r {
      border-right: 2px solid #fff;
    }
    .border-b {
      border-bottom: 2px solid #fff;
    }
    .border-tl-radius {
      border-top-left-radius: 8px;
    }
    .border-tr-radius {
      border-top-right-radius: 8px;
    }

    .row,
    .col {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .col {
      flex-direction: column;
    }

    .fixed-col {
      width: 190px;
      min-height: 56px;
    }

    .avg-col {
      width: calc((100% - 190px) / 3);
      min-height: 56px;
    }

    .left-col,
    .right-col {
      width: 50%;
      height: 112px;
    }

    .t-cell,
    .b-cell {
      width: 100%;
      height: 56px;
    }

    .tips-wrap {
      background: #f9f9f9;
      padding: 16px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .def-tips {
        font-size: 12px;
        line-height: 16px;
        color: #777777;
      }

      .other-tips {
        font-size: 12px;
        line-height: 16px;
        color: #999999;
        padding-top: 8px;
      }
    }
  }

  @media screen and (max-width: 750px) {
    .market-preview-table {
      .header-txt {
        font-size: 13px;
      }
      .cell-txt {
        font-size: 13px;
      }

      .border-r {
        border-right: 1px solid #fff;
      }
      .border-b {
        border-bottom: 1px solid #fff;
      }

      .fixed-col {
        width: 70px;
      }

      .avg-col {
        width: calc((100% - 70px) / 3);
      }

      .left-col,
      .right-col {
        width: 50%;
        height: 116px;
      }

      .left-col {
        writing-mode: vertical-lr;
        letter-spacing: 3px;
      }
      .right-col .center-txt {
        writing-mode: vertical-lr;
        letter-spacing: 2px;
        padding-top: 2px;
      }

      .t-cell,
      .b-cell {
        width: 100%;
        height: 58px;
      }

      .tips-wrap {
        padding: 12px;

        .def-tips {
          font-size: 11px;
        }

        .other-tips {
          font-size: 11px;
          padding-top: 4px;
        }
      }
    }
  }
</style>
