<template>
  <div class="page-header-bar">
    <img class="logo-pic" :src="logoPic" />
    <div class="rule-txt" @click="$emit('link')" v-if="isRule">{{ $t('activitys.activityRule') }}</div>
  </div>
</template>

<script>
  import i18n from '@/language'

  export default {
    name: 'page-header-bar',

    props: {
      isRule: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      logoPic() {
        let logo
        if (i18n.locale === 'zh_CN') {
          logo = require('@/assets/images/common/logo2_cn.webp')
        } else {
          logo = require('@/assets/images/common/logo2_hk.webp')
        }
        return logo
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-header-bar {
    background: #ffffff;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 80px;

    .logo-pic {
      width: 150px;
      height: 36px;
      cursor: pointer;
    }

    .rule-txt {
      font-size: 16px;
      text-align: right;
      color: #333333;
      cursor: pointer;
    }
  }

  @media (min-width: 750px) and (max-width: 1024px) {
    .page-header-bar {
      height: 64px;
      padding: 0 40px;

      .logo-pic {
        width: 120px;
        height: 30px;
      }
    }
  }

  @media screen and (max-width: 750px) {
    .page-header-bar {
      height: 56px;
      padding: 0 20px;

      .logo-pic {
        width: 88px;
        height: 22px;
      }

      .rule-txt {
        font-size: 13px;
      }
    }
  }
</style>
