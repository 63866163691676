import activityFinancing from './data/activity-financing-cn'
import activityStockTrans from './data/activity-stock-trans-cn'
import activityStockTrans2 from './data/activity-stock-trans2-cn'
import activityDayTrade from './data/activity-day-trade-cn'
import activityDoubleFestivals from './data/activity-double-festivals-cn'
import activityPortfolio from './data/activity-portfolio-cn'
import activityPortfolio2 from './data/activity-portfolio2-cn'
import activityPortfolioRead from './data/activity-portfolio-read-cn'
import activityPortfolioFixed from './data/activity-portfolio-fixed-cn'

export default {
  // 通用翻译
  common: {
    confirm: '确定',
    return: '返回',
    cancel: '取消',
    noData: '暂无数据',
    reminder: '温馨提示',
    defaultInputHolder: '请输入',
    defaultSelectHolder: '请选择',
    copySuccess: '复制成功',
    copyNotSupport: '当前设备不支持复制',
    loadMore: '加载更多',
    noMore: '暂无更多',
    open: '打开',
    reqError: '请求失败，请稍后重试',
    stayTuned: '敬请期待',
    commonUse: '常用',
    goAppHandle: '请前往app进行操作',
    placeholder: '请输入搜索关键词',
    relation: '相关',
    result: '结果',
    count: '条',
    suggest: '推荐',
    relief:
      '免责声明：上述内容仅为发布者个人观点，不构成盈宝或本社区的任何投资建议，盈宝或本社区不承担因上述发布者言论引起的任何损失和损害。',
    joinTitle: '令你投资Sure Win',
    situation: '岗位',
    noAccount: '暂无岗位信息',
    publishTime: '发布时间',
    scanCodeTips: '扫码下载APP',
    noSearch: '抱歉，未找到相关信息',
    questionModule: '问题分类',
    downLoadApp: '下载APP',
    languageChoice: '语言选择',
    downLoadText: '下载',
    downloadAppDesc: '盈宝自营投资组合低回撤高收益，全球ETF资产配置',
    noActive: '活动即将上线，敬请期待',
    noCost: '暂无收费信息',
    weixinDownloadTips: '请点击右上角按钮，选择在浏览器中打开',
    openDownloadTips1: '请点击右上角',
    openDownloadTips2: '选择浏览器打开',
    contact: '立即咨询',
    knowMore: '了解更多',
    activeDetail: '活动详情',
    appUpdateTips: '请更新APP最新版本后体验~'
  },

  // 地区
  location: {
    motherCountry: '中国',
    hongKong: '香港',
    maCao: '澳门',
    taiWan: '台湾'
  },

  router: {
    winbull: '盈宝证券官网',
    winbullSite: '盈宝证券官网-盈宝自营投资组合低回撤高收益，全球ETF资产配置',
    fees: '收费',
    member: '会员',
    active: '活动',
    aboutUs: '关于我们',
    helpCenter: '帮助中心',
    newsDetail: '资讯详情',
    searchResult: '搜索结果',
    notice: '消息通知',
    joinUs: '加入盈宝',
    jobDetail: '岗位详情',
    downLoadApp: '下载APP',
    questionModule: '问题模块'
  },

  header: {
    home: '首页',
    fees: '收费',
    member: '会员',
    active: '活动',
    aboutUs: '关于我们',
    helpCenter: '帮助中心',
    joinUs: '加入盈宝',
    zhCn: '简体',
    zhHk: '繁体'
  },

  footer: {
    connectUs: '联系我们',
    consumerHotline: '客服电话',
    other: '其他',
    hongKong: '香港',
    officeHours: '办公时间',
    tradingDay: '交易日',
    followUs: '关注我们',
    appDeveloper: 'APP开发者',
    company: '盈宝证券国际(香港)有限公司',
    appName: '应用名称',
    winbull: '盈宝证券',
    appVersion: '应用版本',
    ios: 'iOS',
    android: '安卓',
    permission: '权限说明',
    privacyAgreement: '隐私权限',
    serviceRule: '用户权限',
    updateTime: '更新时间',
    warning: '市场有风险，投资需谨慎',
    email: '电邮查询',
    fwtk: '服务条款',
    khxy: '客户协议',
    mzsm: '免责声明',
    wlaq: '网络安全',
    yszc: '隐私政策及个人资料收集声明',
    commonService: '一般查询',
    businessService: '商务查询'
  },

  home: {
    banner: {
      title: '一站式',
      title2: '智能化投资平台',
      desc: '全球投资随心所欲，助力您的财富增值'
    },
    specialTitle: '我们的特色',
    ourSpecial: {
      title: '高CP值交易系统',
      subTitle: '自研一站式交易平台',
      title2: '经纪人',
      subTitle2: '全港独家经纪人客户配对平台',
      title3: '极低收费',
      subTitle3: '交易成本,无感体验'
    },
    choiceTitle: '为什么选择盈宝',
    choiceReason: {
      title: '合规持牌',
      desc: '香港证监会认可的持牌',
      desc2: '证券经纪商',
      title2: '安全保障',
      desc3: '客户资产独立托管于银行',
      desc4: '受香港投资者赔偿基金(ICF)保障',
      title3: '全方位支援',
      desc5: '专业，迅速，有温度的客户服务'
    }
  },

  aboutUs: {
    overview: '公司概况',
    overviewDesc:
      '盈宝证券国际（香港）有限公司总部位于香港，致力于打造成新一代互联网券商，为全球投资者提供安全、便捷、高效的国际投资服务。旗下盈宝证券APP于2022年3月发布并投入运营，为全球投资者提供港美股的实时行情、证券交易以及财富管理服务。公司并已获得IDG资本的投资意向，我们正处于发展阶段，发展空间与机会潜力无限。',

    overviewDescVip:
      '我们目前已在香港设立公司，致力于打造成新一代互联网券商，为全球投资者提供安全、便捷、高效的国际投资服务。旗下盈宝证券APP于2022年3月发布并投入运营，为投资者提供港股、美股的实时行情、证券交易与财富管理服务。公司已获得IDG资本，我们正处于发展阶段，发展空间与机会潜力无限，现诚邀您的加盟。',

    overviewItemTitle1: '愿景',
    overviewItemDesc1: '为全球用户提供高效证券投资服务',
    overviewItemTitle2: '价值观',
    overviewItemDesc2: '诚实  判断力  沟通力  影响力  自驱力  好奇心',

    prospects: '发展前景',
    prospectsDesc1: {
      desc: '为香港证监会持牌法团',
      desc1: '公司为香港证监会持牌法团',
      b: '（持牌编号：BRG131）',
      desc2:
        '，现持有证券交易牌照（第1类）、就证券提供意见牌照（第4类）、提供资产管理牌照（第9类）等业务牌照资格及香港联合交易所',
      b2: '（经纪编号：3011）',
      desc3:
        '。后续将会申办新加坡等金融中心之交易会员资格。我们亦将计划推出英文以及其他语言版本证券APP，为全球投资者提供高效投资及管理服务。'
    },
    prospectsDesc2: '公司现正实行创业团队股权激励计划及合伙人计划，以吸引更多的人才加盟。',

    team: '管理及团队',
    teamDesc:
      '公司创始人朱彪，同时亦为球探网联合创始人，项目已启动上市计划。我们的团队拥有丰富的券商以及互联网产品运营经验、卓越的产品研发和创新能力。',

    environment: '公司环境',

    address: '公司地址',
    addressItemTitle1: '盈宝证券国际(香港)有限公司',
    addressItemDesc1: '地址：香港金钟道95号统一中心26楼A室'
  },

  joinUs: {
    title: '期待你加入盈宝证券团队 - 正直 进取 共赢',
    desc1:
      '盈宝是“新一代”互联网券商，总部设于香港。盈宝的业务范围幅射大湾区，网络全球，为全球投资者提供安全高效的投资服务、证券交易及财富管理服务。盈宝作为初创互联网券商，与内地以至东盟国家紧密联系，尽享庞大商机及拓展东南亚机遇。盈宝（香港）为客户提供一个全面化的APP，方便用户随时随地无缝进行投资。',
    desc2:
      '盈宝证券于2022年3月发布并投入运营，集团的团队具有丰富的券商及互联网运营经验，拥有优秀的产品研发和创新能力。为客户提供A股、港股、美股的实时行情、证券交易、专业的财富管理服务。',
    desc3:
      '盈宝为香港证监会持牌法团（持牌编号：BRG131），持有1、4、9号业务牌照资格。为香港证监会核准持牌中介人，获准在香港从事第一类（证券交易）、第四类（就证券提供意见）及第九类（提供资产管理）的受规管活动，亦为香港联合交易所交易参与者。',
    desc4:
      '我们充分发挥企业优势，投资潜力产业，提高经营效益，提升品牌价值，为投资者创造良好的投资回报。敢于创新，挖掘潜力，精益求精，提供优质产品及专业服务，不断提升竞争力。'
  },

  login: {
    signIn: '登录',
    signUp: '注册',
    enterPhone: '请输入手机号',
    pleaseEnterCorrectPhone: '请输入正确的手机号',
    enterVcode: '输入验证码',
    pleaseEnterVcode: '请输入验证码',
    sendVcode: '发送验证码',
    reSendVcode: '重新发送验证码',
    countdown: '倒计时',
    pleasePickUserStatement: '请勾选用户协议',
    firstSignWillRegister: '首次登录将自动注册账号',
    iAgreed: '我已同意',
    serviceAgreement: '服务协议',
    registrationAgreement: '注册协议',
    and: '及',
    privacyAgreement: '隐私协议',

    userAccountLocked: '账号已锁定',
    pleaseCall: '请致电',
    serviceHotline: '服务热线',
    hongkongHotline: '香港',
    otherHotline: '其他',
    signInSuccess: '登录成功',
    registerChannelValid: '原有渠道已失效，点击跳转至官方渠道',

    captchaTitle: '请完成安全验证后继续：',
    captchaTips: '按住左边按钮拖动完成上方拼图',
    captchaVerifySuccess: '验证成功',
    captchaVerifyFail: '验证失败，请重试',
    captchaOutTime: '验证码已过期，请重试'
  },

  activitys: {
    activity: '活动',
    time: '时间',
    activityRule: '活动规则',
    activityRule2: '规则说明',
    activityTime: '活动时间',
    joinActivity: '参与活动',
    joinActivity2: '参加活动',
    immediatelyOpen: '立即开户',
    immediatelyCashIn: '立即入金',
    immediatelyJoin: '立即参与',
    goLogin: '去登录',
    goOpenAccount: '去开户',
    goTrade: '去交易',
    activityNoReady: '活动未开始',
    activityIsEnd: '活动已结束',
    goInApp: '进入盈宝证券APP',
    checkActivityDetail: '查看活动详情',
    knowIt: '已了解',
    pleaseUpdateApp: '请更新APP最新版本后体验',
    activityStatement: '本活动最终解释权归盈宝证券所有'
  },

  activityFinancing: activityFinancing,

  activityStockTrans: activityStockTrans,
  activityStockTrans2: activityStockTrans2,

  activityDayTrade: activityDayTrade,

  activityDoubleFestivals: activityDoubleFestivals,

  activityPortfolio: activityPortfolio,
  activityPortfolio2: activityPortfolio2,

  activityPortfolioRead: activityPortfolioRead,

  activityPortfolioFixed: activityPortfolioFixed,
}
