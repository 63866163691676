// activityPortfolioFixed
export default {
  pageTitle: '盈宝投资组合定投活动',
  shareTitle: '盈宝限时奖励活动',
  shareDesc: '购买投资组合最高奖励1800HKD！',
  reward: '奖励',

  cardOneTitle: '活动奖励',
  cardOneDesc: '在活动期间持有投资组合达标且持有时长达到要求',
  cardOneLabel1: '持有30天且持有市值≥USD5000',
  cardOneLabel2: '持有60天且持有市值≥USD10000',
  cardOneLabel3: '持有90天且持有市值≥USD15000',
  cardOneTips: '注：持有时长是从达到活动要求的持有市值开始计算。',

  cardTwoTitle: '活动试算',
  cardTwoDesc1: '奖励从低到高，定投时间越长，获得的奖励越高；',
  cardTwoDesc2: '以ETF轮动按照目标年化16%计算；',
  cardTwoDesc3: '月平均收益为1.2%，以下为示例：',
  cardTwoTableHeader1: '时间',
  cardTwoTableHeader2: '持有市值',
  cardTwoTableHeader3: '预计收益+奖励',
  cardTwoTableRow1: '持有30天',
  cardTwoTableRow2: '持有60天',
  cardTwoTableRow3: '持有90天',
  cardTwoTableRow4: '总计',
  cardTwoTips: '注：预计收益仅供参考，投资组合的过往表现不能预示日后的表现；上述示例是按7.82港元兑1美元的汇率计算。',

  cardThreeTitle: '产品特点',
  cardThreeLabel1: '轻松获取活动奖励+预期收益高达<span>HKD 4600</span>，实际保底奖励金额收益高达年化<span>12%</span>',
  cardThreeLabel2: '享最佳汇率优势，港元兑美元汇率买卖价差不超过<span>0.05%</span>',
  cardThreeLabel3:
    '总资产大于30万港币的客户，即可享最优惠的会员制度，不仅<span>港美股0佣金</span>，融资利率更是低至<span>5.6%</span>',

  cardFourTitle: '活动规则',
  cardFourLabel1:
    '此活动面向盈宝证券所有客户，包括新客户和老客户，本活动不与任何其它投资组合活动叠加参与。过往获得过投资组合奖励的，不能再次获得奖励。',
  cardFourLabel2:
    '活动所对应的奖励金额，如满足持有市值和时长，将在达标后发放相应的奖励金额，奖励金额将以现金券的形式发放给合资格的客户。',
  cardFourLabel3:
    '本次活动产品为盈宝投资组合产品，需满足最低持有时长30天，单次投资金额不低于5000美金，若客户不采用定投方式，选择一次性投入并满足持有时长后，也可获得投入资金所对应的奖励。',
  cardFourLabel4:
    '如客户中途转出则代表客户自愿放弃活动奖励，已发放的奖励金额无需退还，未满足实际持有天数的奖励金额则视为客户自动放弃活动资格。',

  pageTips:
    '免责声明<br/>1、此活动由盈宝证券（香港）有限公司提供。本活动并非及不应被视为任何证券、金融产品或工具邀约、招揽、邀请、建议、意见或任何投资决策的建议，亦不应被视为专业意见。流览本活动的人士在作出任何投资决定之前，应完全了解其风险以及相关法律，涉及的税项及会计的特点及后果，并根据个人的情况决定投资是否适合个人的财政状况及投资目标，以及有关风险的承受能力，有需要时应寻找适当的专业意见。<br/>2、盈宝证券（香港）在法律规定范围内保留更改此条款及细则，改或终止任何优惠的权利，而毋须另行通知。本活动的奖励资格及数据确认，以盈宝证券（香港）后台数据为准，如有任何异议，盈宝证券（香港）保留最终解释权及决定权。<br/>3、如对本次活动有任何查询需要，请致电盈宝证券（香港）客服专线（852）23709538。'
}
