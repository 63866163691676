// activityPortfolio
export default {
  pageTitle: '盈宝投资组合福利巨献',
  shareTitle: '盈宝投资组合福利巨献！',
  shareDesc: '开户送股票，认购送现金！',

  btnTxt: '立即认购',
  reward: '奖励',

  cardOneTitle1: '开户送股票',
  cardOneTitle2: '认购送现金',
  cardOneLabel1: '福特（NYSE：F）2股',
  cardOneLabel2: '300港币现金券',
  joinUser: '参与用户：',
  joinCondition: '参与条件：',
  cardOneValue11: '在活动期间首笔入金达到2万(含)港币以上的用户',
  cardOneValue12: '成功开通盈宝投资组合账户',
  cardOneValue21: '首次认购盈宝投资组合的用户',
  cardOneValue22: '认购任意一个盈宝投资组合，连续持有满30天且无转出资金',

  cardTwoTitle: '市场表现',
  cardTwoTableHeader1: '组合名称',
  cardTwoTableHeader2: '盈宝稳健收益宝',
  cardTwoTableHeader3: '盈宝全球ETF收益增强',
  cardTwoTableHeader4: '盈宝全球精选ETF轮动',
  cardTwoTableLabel1: '收益率',
  cardTwoTableLabel2: '回撤率',
  cardTwoTableLabel3: '美国市场',
  cardTwoTableLabel4: '香港市场',
  cardTwoTableValue1: '债券基金',
  cardTwoTableValue2: '混合基金',
  cardTwoTableValue3: '股票基金',
  cardTwoTips1:
    'wind统计数据：2023.01.01-2023.12.19；以上为三个盈宝投资组合分别对比美国、香港市场共计3万只同类型基金的业绩表现。',
  cardTwoTips2: '注：历史收益率及回撤率仅供参考，投资组合的过往表现不能预示日后的表现。',

  cardThreeTitle: '新手必读',
  cardThreeLabel1: '盈宝投资组合的投资理念',
  cardThreeLabel2: '盈宝投资组合的投资策略',
  cardThreeLabel3: '盈宝投资组合的产品介绍',
  cardThreeLabel4: '认购盈宝投资组合是否安全',
  cardThreeLabel5: '如何选择适合自己的投资组合',
  cardThreeLabel6: '如何做好投资资金及投资期限规划',

  cardFourTitle: '常见问题',
  cardFourQ1: '奖励如何发放？',
  cardFourA1:
    '1、满足首笔入金条件的用户，成功开通投资组合账户后，系统72小时内自动派发2股福特（NYSE: F）股票券到卡券中心，用户在【我的】-【我的卡券】进行领取激活，卡券派发后15天内如未激活使用，则会失效，将视为用户自动放弃。<br/>2、满足首次认购盈宝投资组合的用户，连续持有满30天且无转出资金，达到条件后系统72小时内自动派发300港币现金券到卡券中心，用户在【我的】-【我的卡券】进行领取激活，卡券派发后15天内如未激活使用，则会失效，将视为用户自动放弃。',
  cardFourQ2: '在哪里认购盈宝投资组合？',
  cardFourA2: '盈宝证券APP-投资组合频道，选择你想认购的投资组合，点击【投入】按钮并输入投入金额确认提交。',
  cardFourQ3: '账户只有港元，可以认购吗？',
  cardFourA3: '盈宝投资组合需用美元认购，如用户账户只有港元也可以直接认购，系统会自动进行换汇认购。',
  cardFourQ4: '认购后，在哪里查看我的投资组合收益？',
  cardFourA4: '可以在盈宝证券APP-【资产】频道查看组合收益情况。',
  cardFourQ5: '是否有认购费，赎回费？',
  cardFourA5: '认购盈宝投资组合，不收取认购费和赎回费。',

  pageTips:
    '免责声明：<br/>1、此活动由盈宝证券（香港）有限公司提供。 本活动并非及不应被视为任何证券、金融产品或工具邀约、招揽、邀请、建议、意见或任何投资决策的建议，亦不应被视为专业意见。 流览本活动的人士在作出任何投资决定之前，应完全了解其风险以及相关法律，涉及的税项及会计的特点及后果，并根据个人的情况决定投资是否适合个人的财政状况及投资目标，以及有关风险的承受能力，有需要时应寻找适当的专业意见。<br/>2、盈宝证券（香港）在法律规定范围内保留更改此条款及细则，更改或终止任何优惠的权利，而毋须另行通知。 本活动的奖励资格及数据确认，以盈宝证券（香港）后台数据为准，如有任何争议，盈宝证券（香港）保留最终解释权及决定权。<br/>3、如对本次活动有任何查询需要，请致电盈宝证券（香港）客服专线（852）23709538。'
}
