// activityPortfolioRead
export default {
  pageTitle: '投资组合新手必读',

  tab1: '理念',
  tab2: '策略',
  tab3: '产品',
  tab4: '安全',
  tab5: '建议',
  tab6: '配置',

  tab1Title: '盈宝投资组合的投资理念',
  tab1Txt1:
    '在投资的世界里，风险与收益是伴随而生的。为保护用户的资金安全并实现稳定的财富增值，盈宝投资组合坚守<span>“风险与收益平衡，稳为先，严控回撤”</span>的投资理念。',
  tab1Txt2:
    '高收益背后可能潜藏着高风险，这要求投资者能及时识别风险错配、合理提升风险补偿，使风险与收益处于长期动态平衡。盈宝投研团队深知投资过程中的风险不可避免，但致力于通过科学的风险管理方法和严格的风险控制措施，将投资的风险降到最低，使整体投资回报达到最优状态。',
  tab1Txt3:
    '稳定的投资回报是投资者最需要的，而不是一时的高飞。因此，盈宝投资组合的构建和管理中，始终将稳定性放在首位。通过多样化的资产配置和定期的风险评估，努力降低投资组合的波动性，为用户的投资提供更稳定的增长，只有在稳健的基础上才能获得持续的投资回报。',
  tab1Txt4:
    '盈宝投资组合严控回撤，守护用户的财富增值。回撤是投资过程中的一种风险，严控回撤是保证财富增值的关键。盈宝投研团队通过定期的风险监控和灵活的资产配置，及时应对市场波动，最大限度地减少回撤风险，注重投资组合的稳健性，确保用户的财富在市场波动中得到更为有效的保护。',

  tab2Title: '盈宝投资组合的投资策略',
  tab2Txt1:
    '投资是实现财富成长的关键之一，而选择有效的投资策略更是至关重要。盈宝投资组合采用了<span>「动量+轮动」</span>策略，目的是透过灵活的资产配置和追随市场的热点，为用户带来更好的投资回报。',
  tab2Txt2:
    '首先，投资策略融入了「动量」因素。投研团队会密切注意市场的走势和各类资产表现，并根据市场的动能进行投资决策。当某资产的价格表现良好且市场趋势向上时，会适时增加对该资产的配置比例，以追随市场的热点。透过大数据分析捕捉的领先讯号，我们能快速进行决策，提升组合的投资回报率。',
  tab2Txt3:
    '其次，盈宝投资组合采用「轮动」策略，根据市场各板块的表现，灵活调整投资组合的配置。当市场行情良好时，及时增加表现较好的板块的配置比例。而当市场行情趋于疲软时，及时减少表现较差的板块的配置比例。透过这种「轮动」的策略，更好地在市场变化中抓住机会，提高整体投资组合的回报。',
  tab2Txt4:
    '此投资策略根据市场行情和资产表现灵活调整投资组合的配置，能够及时抓住市场机会，避免因单一资产的不利影响而造成损失。同时，也会密切关注市场的动量，以追随市场的热点，为客户提供更好的投资回报。',
  tab2Txt5:
    '盈宝投研团队拥有丰富的经验，结合自研的大数据分析系统、智能资产评分系统及量化投资系统，能够更好判断市场的走势和资产的表现，以确保投资决策的准确性和及时性。',

  tab3Title: '盈宝投资组合的产品介绍',
  tab3Txt1:
    '世界上不存在完美的投资，任何股票都要通过投资组合来分散风险，投资组合能起到提高整体收益、分散风险的作用。虽然永远得不到其中一类资产的最佳回报，但是也不会遭遇任何一类资产的最差回报。这就是做资产配置的出发点。',
  tab3Txt2:
    '投资是很专业的事，基本原则是让专业的人做专业的事。盈宝构建“投资组合”的初衷是让中小客户也可以享受专业的投研服务！同时，秉承“风险与收益匹配，稳为先，严控回撤”的投资理念，为投资者创造长期资本增值和控制投资风险，让客户的投资能够长期稳健盈利。',
  tab3Txt3: '盈宝为不同风险偏好的投资者提供了三种投资组合：',

  portfolioLabel1: '组合目标：',
  portfolioLabel2: '资产配置：',
  portfolioLabel3: '投资策略：',
  portfolioLabel4: '组合风险：',
  portfolioLabel5: '管理费（年率）：',
  portfolioLabel6: '适合人群：',

  portfolio1Title: '盈宝稳健收益宝',
  portfolio1Value1: '年化收益率大于6%，最大回撤小于2%',
  portfolio1Value2: '全球资产配置，约65%债券ETF ，35%股票或商品型ETF',
  portfolio1Value3: '动量+轮动',
  portfolio1Value4: 'R2，中低风险',
  portfolio1Value5: '1%',
  portfolio1Value6: '风险承受能力相对较低的稳健型投资者',

  portfolio2Title: '盈宝全球ETF收益增强',
  portfolio2Value1: '年化收益率大于11%，最大回撤小于5%',
  portfolio2Value2: '全球资产配置，约35%债券ETF ，65%股票或商品型ETF',
  portfolio2Value3: '动量+轮动',
  portfolio2Value4: 'R3，中风险',
  portfolio2Value5: '1.5%',
  portfolio2Value6: '有一定的风险承受能力，希望追求中高收益的平衡型投资者',

  portfolio3Title: '盈宝全球精选ETF轮动',
  portfolio3Value1: '年化收益率大于16%，最大回撤小于9%',
  portfolio3Value2: '全球资产配置，约100%股票或商品型ETF',
  portfolio3Value3: '动量+轮动',
  portfolio3Value4: 'R4，中高风险',
  portfolio3Value5: '2%',
  portfolio3Value6: '有较强的风险承受能力，希望追求高收益的积极型投资者',
  tab3Txt4: '盈宝三款投资组合表现对比市场基金情况',
  tab3Txt5:
    '盈宝三款投资组合对比美国、香港市场共计3万多只同类型基金，均展现出令人惊喜的业绩表现。在费用方面，盈宝投资组合仅收取管理费，无其他费用，对比市场同类型基金产品具有极强的低费率优势。相信在不同的市场环境和经济周期中，盈宝通过对全球市场的分析、积极的资产配置和严谨的风险管理，盈宝投资组合能为投资者提供优异的业绩表现和投资回报。',

  tab4Title: '认购盈宝投资组合是否安全',
  tab4Txt1:
    '盈宝证券面向全球客户提供专业的一站式金融投资服务，公司愿景是通过互联网券商 + 资产管理的业务模式，为客户实现投资盈利提供帮助。 凭借资管业务优异的业绩表现和客户口碑获得市场认可，盈宝证券荣获2023年第五届“金中环”资管业务最佳表现奖！',
  tab4Txt2: '一、合法合规',
  tab4Txt3:
    '盈宝证券为香港证监会持牌法团（持牌编号：BRG131），持有1、4、9号业务牌照资格。拥有9号牌可以发行自己的基金产品管理基金，进行PE（私募股权基金投资）代客操盘，专户理财以及私人银行基金经理(EAM)等业务，所以盈宝投资组合属于完全合法合规的方式。',
  tab4Txt4: '二、高透明度',
  tab4Txt5:
    '相较于市面上大多理财产品，盈宝投资组合在信息透明度上更胜一筹。通过智能资产评分系统运用多项综合指标和大数据分析每周定期评估，按周发布调仓详情公告，告知资产配置调整方向，帮助投资者及时全面了解详细资产配置，增加产品的信息对称性。',
  tab4Txt6: '三、严格风控',
  tab4Txt7:
    '盈宝投资组合采用严格的风险控制策略，利用大数据构建的风险模型，优化组合的收益和风险平衡，通过预警监控和大数据分析及时调整组合配置和权重，定期进行压力测试和回溯测试，评估组合在极端情况下的表现和风险，从而更好把控投资风险。',
  tab4Txt8: '正规安全是我们给予用户最基本的保障！让客户不仅可以在盈宝省心投资，更可以安心投资、放心投资。',

  tab5Title: '如何选择适合自己的投资组合',
  tab5Txt1:
    '选择适合自己的投资组合是投资成功的关键，一个好的投资组合能够平衡风险和回报，根据个人的财务目标和风险承受能力，最大程度地增加投资利润。',
  tab5Txt2: '一、评估风险承受能力',
  tab5Txt3:
    '风险和收益通常是成正比的，风险越高，回报也可能越高，但是也会承担更大的损失风险。了解自己的风险承受能力对于选择适合的投资组合至关重要。评估自己的风险承受能力可以考虑自己的年龄、收入、负债以及对投资的经验和知识水平等因素，才能精准地定位自己的风险承受能力。',
  tab5Txt4: '二、对不同投资者的建议',
  tab5Txt5: '稳健型投资者：',
  tab5Txt6:
    '这类投资者的风险偏好比较中性，具有一定的风险承受能力，在追求稳定收益的同时对本金的安全性有一定的要求。相对来说，稳健型投资者比较倾向于收益适中，资金安全有保障。盈宝稳健收益宝投资组合主要以债券型基金为主，属于中低风险产品，力求实现年化收益率大于6%，最大回撤小于2%的收益目标，是大多数稳健型投资者的首选理财标杆。',
  tab5Txt7: '平衡型投资者：',
  tab5Txt8:
    '这类投资者相对比较理性，不过度厌恶风险，也不过度冒险，追求风险与收益成一定比例。相对来说，比保守型和稳健型投资者，承受的风险要高一点，对收益的追求更高一点。盈宝全球ETF收益增强投资组合主要以股票型和债券型ETF为主，其中股票型ETF占比约65%，属于中风险产品。该组合采用均衡配置的策略，在构建底层债券资产收益的基础上，通过灵活配置股票型资产来进一步增强组合的收益，力求实现年化收益率大于11%，最大回撤小于5%的收益目标，是大部分平衡型投资者的最佳选择。',
  tab5Txt9: '积极型投资者：',
  tab5Txt10:
    '这类投资者比较享受追求收益的过程，能承受住相匹配的风险，能接受风险带来的一定程度上的亏损。积极型投资者，会选择主动出击，在投资的热情上，远高于前三类投资者，也相对比较乐观，对收益的追求相对比较高，基本上是10%以上。盈宝全球精选ETF轮动投资组合以股票型ETF为主要投资标的，属于中高风险产品。该组合采用多元化配置的策略，通过精选具有较高增长潜力的全球股票型ETF，力求实现年化收益率大于16%，最大回撤小于9%的收益目标，是积极型投资者的实现资产较大幅度增值的最佳选择。',

  tab6Title: '如何做好投资组合资金及期限规划',
  tab6Txt1:
    '在实施个人投资计划之前，制定合理的投资资金与期限规划是非常重要的。通过合理规划投资资金和期限的分配，可以帮助投资者更好地控制风险、提高收益，并实现财务目标。在制定投资资金和期限规划时，还需要评估自己的风险承受能力，风险承受能力会决定投资者愿意承担的风险程度。以下是一些常见的投资时间及投资资金规划建议供您参考。',
  tab6Txt2: '一、投资期限规划',
  tab6Txt3: '长期投资：',
  tab6Txt4:
    '长期投资的时间范围为3-5年以上，如是寻求相对长期稳健回报的投资者，建议选择盈宝稳健收益宝组合。如果希望将投资期限拉长以降低整体风险，追求更高收益的投资者，则适合选择盈宝全球精选ETF轮动组合。',
  tab6Txt5: '中期投资：',
  tab6Txt6:
    '中期投资的时间范围为1-3年，如果您希望在中期内实现资本增值并平衡风险和回报，可以考虑选择中等风险的盈宝全球ETF收益增强组合。',
  tab6Txt7: '短期投资：',
  tab6Txt8:
    '短期投资的时间范围为数月至1年，如果您的目标是在短期内获得快速回报，可以考虑选择中高风险的盈宝全球精选ETF轮动组合。',
  tab6Txt9: '二、投资资金规划',
  tab6Txt10: '注重资金增值：',
  tab6Txt11:
    '如果您是稳健型投资者，注重资金的安全和稳定增值，建议将大部分投资资金分配给盈宝稳健收益宝组合，该组合风险较低，能够提供相对稳定的回报。可以考虑将投资资金的70% - 80%分配给盈宝稳健收益宝组合，将剩余的部分分配给盈宝全球ETF收益增强组合，以获得一定的增长潜力。',
  tab6Txt12: '追求资金回报：',
  tab6Txt13:
    '如果您是平衡型投资者，希望在保持资金相对安全的同时，追求一定的增长回报，建议将投资资金分散分配给中低风险和中风险的投资组合。可以考虑将投资资金的50% - 60%分配给盈宝稳健收益宝组合，将剩余的部分分配给盈宝全球ETF收益增强组合，以平衡风险和回报。',
  tab6Txt14: '追求较高回报：',
  tab6Txt15:
    '如果您是积极型投资者，对风险有较高承受能力，希望追求较高的回报，可以将一部分投资资金分配给中风险和中高风险的投资组合。可以考虑将投资资金的30% - 40%分配给盈宝全球ETF收益增强组合，将剩余的部分分配给盈宝全球精选ETF轮动，以充分利用高风险高回报的特点。',
  tab6Txt16:
    '以上投资时间及投资资金规划建议仅供参考，最适合您的投资规划还需根据您的个人财务目标、风险承受能力和投资偏好来确定。'
}
