<template>
    <div ref="qrCode"/>
</template>
<script>
  import QRCode from 'qrcodejs2'
  export default {
    name: 'qr-code',
    props: {
      options: Object
    },
    data() {
      return {
        h5Link: ''
      }
    },
    mounted() {
      const pathname = window.location.pathname
      const index = pathname.lastIndexOf('/')
      const prefix = pathname.substr(0, index + 1)
      this.h5Link = window.location.origin + prefix + 'downMobile.html'
      new QRCode(this.$refs.qrCode, {
        width: this.options.size,
        height: this.options.size,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
        text: this.h5Link
      })
    }
  }
</script>
<style lang="scss" scoped>
  .qr-code {
    background: #fff;
    margin: 40px auto 0;
    overflow: hidden;
  }
</style>
