// activityPortfolioRead
export default {
  pageTitle: '投資組合新手必讀',

  tab1: '理念',
  tab2: '策略',
  tab3: '產品',
  tab4: '安全',
  tab5: '建議',
  tab6: '配置',

  tab1Title: '盈寶投資組合的投資理念',
  tab1Txt1:
    '在投資的世界裡，風險與利益是伴隨而生的。為保護用戶的資金安全並實現穩定的財富增值，盈寶投資組合堅守<span>「風險與收益平衡，穩為先，嚴控回撤」</span>的投資理念。',
  tab1Txt2:
    '高收益背後可能潛藏著高風險，這要求投資者能及時識別風險錯配、合理提升風險補償，使風險與收益處於長期動態平衡。盈寶投研團隊深知投資過程中的風險不可避免，但致力於透過科學的風險管理方法和嚴格的風險控制措施，將投資的風險降到最低，使整體投資回報達到最優狀態。',
  tab1Txt3:
    '穩定的投資回報是投資者最需要的，而不是一時的高飛。因此，在盈寶投資組合的建構與管理中，始終將穩定性放在首位。透過多樣化的資產配置和定期的風險評估，努力降低投資組合的波動性，為用戶的投資提供更穩定的成長，只有在穩健的基礎上才能獲得持續的投資回報。',
  tab1Txt4:
    '盈寶投資組合嚴控回撤，守護用戶的財富增值。回撤是投資過程中的風險，嚴控回撤是確保財富增值的關鍵。盈寶投研團隊透過定期的風險監控和靈活的資產配置，及時應對市場波動，最大限度地減少回撤風險，注重投資組合的穩健性，確保用戶的財富在市場波動中得到更有效的保護 。',

  tab2Title: '盈寶投資組合的投資策略',
  tab2Txt1:
    '投資是實現財富成長的關鍵之一，而選擇有效的投資策略更是至關重要。盈寶投資組合採用了<span>「動量+輪動」</span>策略，目的是透過靈活的資產配置和追隨市場的熱點，為用戶帶來更好的投資回報。',
  tab2Txt2:
    '首先，投資策略融入了「動量」因素。投研團隊會密切注意市場的走勢及各類資產表現，並依據市場的動能進行投資決策。當某資產的價格表現良好且市場趨勢向上時，會適時增加對該資產的配置比例，以追隨市場的熱點。透過大數據分析捕捉的領先訊號，我們能快速進行決策，提升組合的投資回報率。',
  tab2Txt3:
    '其次，盈寶投資組合採用「輪動」策略，根據市場各板塊的表現，靈活調整投資組合的配置。當市場行情良好時，及時增加表現較好的板塊的配置比例。而當市場行情趨於疲軟時，及時減少表現較差的板塊的配置比例。透過這種「輪動」的策略，更好地在市場變化中抓住機會，提高整體投資組合的回報。',
  tab2Txt4:
    '此投資策略根據市場行情和資產表現靈活調整投資組合的配置，能夠及時抓住市場機會，避免因單一資產的不利影響而造成損失。同時，也會密切關注市場的動量，以追隨市場的熱點，為客戶提供更好的投資回報。',
  tab2Txt5:
    '盈寶投研團隊擁有豐富的經驗，結合自研的大數據分析系統、智能資產評分系統及量化投資系統，能夠更好判斷市場的走勢和資產的表現，以確保投資決策的準確性和及時性。',

  tab3Title: '盈寶投資組合的產品介紹',
  tab3Txt1:
    '世界上不存在完美的投資，任何股票都要透過投資組合來分散風險，投資組合能起到提高整體收益、分散風險的作用。雖然永遠得不到其中一類資產的最佳回報，但也不會遭遇任何一類資產的最差回報。這就是做資產配置的出發點。',
  tab3Txt2:
    '投資是很專業的事，基本原則是讓專業的人做專業的事。盈寶建構「投資組合」的初衷是讓中小客戶也可以享受專業的投研服務！ 同時，秉持著「風險與收益匹配，穩為先，嚴控回撤」的投資理念，為投資人創造長期資本增值與控制投資風險，讓客戶的投資能長期穩健獲利。',
  tab3Txt3: '盈寶為不同風險偏好的投資者提供了三種投資組合：',

  portfolioLabel1: '組合目標：',
  portfolioLabel2: '資產配置：',
  portfolioLabel3: '投資策略：',
  portfolioLabel4: '組合風險：',
  portfolioLabel5: '管理費（年率）：',
  portfolioLabel6: '適合人群：',

  portfolio1Title: '盈寶穩健收益寶',
  portfolio1Value1: '年化報酬率大於6%，最大回撤小於2%',
  portfolio1Value2: '全球資產配置，約65%債券ETF ，35%股票或商品型ETF',
  portfolio1Value3: '動量+輪動',
  portfolio1Value4: 'R2，中低風險',
  portfolio1Value5: '1%',
  portfolio1Value6: '風險承受能力相對較低的穩健型投資者',

  portfolio2Title: '盈寶全球ETF收益增強',
  portfolio2Value1: '年化報酬率大於11%，最大回撤小於5%',
  portfolio2Value2: '全球資產配置，約35%債券ETF ，65%股票或商品型ETF',
  portfolio2Value3: '動量+輪動',
  portfolio2Value4: 'R3，中風險',
  portfolio2Value5: '1.5%',
  portfolio2Value6: '有一定的風險承受能力，希望追求中高收益的平衡型投資者',

  portfolio3Title: '盈寶全球精選ETF輪動',
  portfolio3Value1: '年化報酬率大於16%，最大回撤小於9%',
  portfolio3Value2: '全球資產配置，約100%股票或商品型ETF',
  portfolio3Value3: '動量+輪動',
  portfolio3Value4: 'R4，中高風險',
  portfolio3Value5: '2%',
  portfolio3Value6: '有較強的風險承受能力，希望追求高收益的積極型投資者',
  tab3Txt4: '盈寶三款投資組合表現對比市場基金情況',
  tab3Txt5:
    '盈寶三款投資組合對比美國、香港市場共3萬多隻同類型基金，均展現出令人驚喜的業績表現。在費用方面，盈寶投資組合僅收取管理費，無其他費用，對比市場同類型基金產品具有極強的低費率優勢。相信在不同的市場環境和經濟週期中，盈寶透過對全球市場的分析、積極的資產配置和嚴謹的風險管理，盈寶投資組合能為投資者提供優異的業績表現和投資回報。',

  tab4Title: '認購盈寶投資組合是否安全',
  tab4Txt1:
    '盈寶證券為全球客戶提供專業的一站式金融投資服務，公司願景是透過互聯網券商 + 資產管理的業務模式，為客戶實現投資盈利提供幫助。憑藉資管業務優異的業績表現及客戶口碑獲得市場認可，盈寶證券榮獲2023年第五屆「金中環」資管業務最佳表現獎！ ',
  tab4Txt2: '一、合法合規',
  tab4Txt3:
    '盈寶證券為香港證監會持牌法團（持牌編號：BRG131），持有1、4、9號業務執照資格。擁有9號牌可以發行自己的基金產品管理基金，進行PE（私募股權基金投資）代客操盤，專戶理財以及私人銀行基金經理(EAM)等業務，所以盈寶投資組合屬於完全合法合規 的方式。',
  tab4Txt4: '二、高透明度',
  tab4Txt5:
    '相較於市面上大多理財產品，盈寶投資組合在信息透明度上更勝一籌。透過智慧資產評分系統運用多項綜合指標及大數據分析每週定期評估，按週發布調倉詳情公告，告知資產配置調整方向，幫助投資人及時全面了解詳細資產配置，增加產品的信息對稱性。',
  tab4Txt6: '三、嚴格風控',
  tab4Txt7:
    '盈寶投資組合採用嚴格的風險控制策略，利用大數據建構的風險模型，優化組合的收益和風險平衡，透過預警監控和大數據分析及時調整組合配置和權重，定期進行壓力測試和回溯測試， 評估組合在極端情況下的表現和風險，以便更好把控投資風險。',
  tab4Txt8: '正規安全是我們給予使用者最基本的保障！ 讓顧客不僅可以在盈寶省心投資，更可以安心投資、放心投資。',

  tab5Title: '如何選擇適合自己的投資組合',
  tab5Txt1:
    '選擇適合自己的投資組合是投資成功的關鍵，一個好的投資組合能夠平衡風險和回報，根據個人的財務目標和風險承受能力，最大程度地增加投資利潤。',
  tab5Txt2: '一、評估風險承受能力',
  tab5Txt3:
    '風險和收益通常是成正比的，風險越高，回報可能越高，但是也會承擔更大的損失風險。了解自己的風險承受能力對於選擇適合的投資組合至關重要。評估自己的風險承受能力可以考慮自己的年齡、收入、負債以及對投資的經驗和知識水平等因素，才能精準地定位自己的風險承受能力。',
  tab5Txt4: '二、不同投資者的建議',
  tab5Txt5: '穩健型投資者：',
  tab5Txt6:
    '這類投資人的風險偏好較為中性，具有一定的風險承受能力，在追求穩定收益的同時對本金的安全性有一定的要求。相對來說，穩健型投資人比較傾向收益適中，資金安全有保障。盈寶穩健收益寶投資組合主要以債券型基金為主，屬於中低風險產品，力求實現年化收益率大於6%，最大回撤小於2%的收益目標，是大多數穩健型投資者的首選 理財標竿。',
  tab5Txt7: '平衡型投資者：',
  tab5Txt8:
    '這類投資人相對比較理性，不過度厭惡風險，也不過度冒險，追求風險與收益成一定比例。相對來說，比保守型和穩健型投資者，承受的風險要高一點，對收益的追求更高一點。盈寶全球ETF收益增強投資組合主要以股票型及債券型ETF為主，其中股票型ETF佔比約65%，屬於中風險產品。此組合採用均衡配置的策略，在建構底層債券資產收益的基礎上，透過靈活配置股票型資產來進一步增強組合的收益，力求實現年化收益率大於11%，最大回撤小於5%的收益目標 ，是大部分平衡型投資者的最佳選擇。',
  tab5Txt9: '積極型投資者：',
  tab5Txt10:
    '這類投資人比較享受追求收益的過程，能承受住相符的風險，能接受風險帶來的一定程度上的虧損。積極型投資者，會選擇主動出擊，在投資的熱情上，遠高於前三類投資者，也相對比較樂觀，對收益的追求相對比較高，基本上是10%以上。盈寶全球精選ETF輪動投資組合以股票型ETF為主要投資標的，屬於中高風險產品。該組合採用多元化配置的策略，透過精選具有較高成長潛力的全球股票型ETF，力求實現年化收益率大於16%，最大回撤小於9%的收益目標，是積極型投資者的實現 資產較大幅度增值的最佳選擇。',

  tab6Title: '如何做好投資組合資金及期限規劃',
  tab6Txt1:
    '在實施個人投資計劃之前，制定合理的投資資金與期限規劃是非常重要的。透過合理規劃投資資金和期限的分配，可以幫助投資者更好地控制風險、提高收益，並實現財務目標。在製定投資資金和期限規劃時，也需要評估自己的風險承受能力，風險承受能力會決定投資者願意承擔的風險程度。以下是一些常見的投資時間及投資資金規劃建議供您參考。',
  tab6Txt2: '一、投資期限規劃',
  tab6Txt3: '長期投資：',
  tab6Txt4:
    '長期投資的時間範圍為3-5年以上，如是尋求相對長期穩健回報的投資者，建議選擇盈寶穩健收益寶組合。如果希望將投資期限拉長以降低整體風險，追求更高收益的投資者，則適合選擇盈寶全球精選ETF輪動組合。',
  tab6Txt5: '中期投資：',
  tab6Txt6:
    '中期投資的時間範圍為1-3年，如果您希望在中期內實現資本增值並平衡風險和回報，可以考慮選擇中等風險的盈寶全球ETF收益增強組合。',
  tab6Txt7: '短期投資：',
  tab6Txt8:
    '短期投資的時間範圍為數月至1年，如果您的目標是在短期內獲得快速回報，可以考慮選擇中高風險的盈寶全球精選ETF輪動組合。',
  tab6Txt9: '二、投資資金規劃',
  tab6Txt10: '注重資金增值：',
  tab6Txt11:
    '如果您是穩健型投資者，注重資金的安全和穩定增值，建議將大部分投資資金分配給盈寶穩健收益寶組合，該組合風險較低，能夠提供相對穩定的回報。可以考慮將投資資金的70% - 80%分配給盈寶穩健收益寶組合，將剩餘的部分分配給盈寶全球ETF收益增強組合，以獲得一定的成長潛力。',
  tab6Txt12: '追求資金回報：',
  tab6Txt13:
    '如果您是平衡型投資者，希望在保持資金相對安全的同時，追求一定的成長回報，建議將投資資金分散分配給中低風險和中風險的投資組合。可以考慮將投資資金的50% - 60%分配給盈寶穩健收益寶組合，將剩餘的部分分配給盈寶全球ETF收益增強組合，以平衡風險和回報。',
  tab6Txt14: '追求較高回報：',
  tab6Txt15:
    '如果您是積極型投資者，對風險有較高承受能力，希望追求較高的回報，可以將一部分投資資金分配給中風險和中高風險的投資組合。可以考慮將投資資金的30% - 40%分配給盈寶全球ETF收益增強組合，將剩餘的部分分配給盈寶全球精選ETF輪動，以充分利用高風險高回報的特點。',
  tab6Txt16:
    '以上投資時間及投資資金規劃建議僅供參考，最適合您的投資規劃還需根據您的個人財務目標、風險承受能力和投資偏好來確定。'
}
