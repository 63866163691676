const activityPageConfig = require('./activity-page')

//这里做页面参数约定和说明，如果url没有携带requiredParams的参数则无法初始化页面
module.exports = {
  // 活动页面
  ...activityPageConfig,

  index: {
    title: '盈宝证券官网-盈宝自营投资组合低回撤高收益，全球ETF资产配置',
    requiredParams: {}
  },
  news: {
    title: '',
    requiredParams: {}
  },
  about: {
    title: '',
    requiredParams: {}
  },
  help: {
    title: '',
    requiredParams: {}
  },
  searchResult: {
    title: '',
    requiredParams: {}
  },
  newsDetail: {
    title: '',
    requiredParams: {}
  },
  helpContent: {
    title: '',
    requiredParams: {}
  },
  mobileQuestionModule: {
    title: '',
    requiredParams: {}
  },
  join: {
    title: '',
    requiredParams: {}
  },
  joinDetail: {
    title: '',
    requiredParams: {}
  },
  fees: {
    title: '',
    requiredParams: {}
  },
  activity: {
    title: '',
    requiredParams: {}
  },
  downPc: {
    title: '',
    requiredParams: {}
  },
  downMobile: {
    title: '',
    requiredParams: {}
  },
  notice: {
    title: '',
    requiredParams: {}
  }
}
