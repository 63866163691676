// activityDoubleFestivals
export default {
  pageTitle: '盈寶年旦三重賞',
  shareTitle: '盈寶年旦三重賞 重重有驚喜',
  shareDesc: '獎勵高達萬元港幣，享受雙旦',

  immediatelyJoin: '立即參與免佣福利',

  cardOneTitle1: '新註冊用戶首次入金達標',
  cardOneTitle2: '獎勵高達 HKD 4300',
  cardOneHeaderFlag: '一重禮',
  cardOneTabelHeader1: '首入金金額(HKD)',
  cardOneTabelHeader2: '獎勵',
  worth: '價值',
  newUserGift: '新人禮包',
  stockCard: '股票卡',
  futeStock: '福特(NYSE:F)',
  stockCount: '股',
  cardOneTips: '提示：取得股票卡需在首入金達標後留存30天並交易≥1次；新人禮包請',
  seeMore: '查看詳情',

  cardTwoTitle1: '交易港/美股日內融筆數達標',
  cardTwoTitle2: '獎勵高達 HKD 1500',
  cardTwoHeaderFlag: '二重禮',
  cardTwoTabelHeader1: '交易港/美股',
  cardTwoTabelHeader12: '日內融筆數',
  cardTwoTabelHeader2: '獎勵金額',
  cardTwoTabelHeader3: '累計獎勵',
  cashCoupon: '現金券',
  tradeCount: '筆',
  cardTwoTips: '提示：交易美股每筆金額需大於或等於10美元。',

  cardThreeTitle1: '盈寶正股融資 0 利息',
  cardThreeTitle2: '高達 HKD 300萬 融資免息',
  cardThreeHeaderFlag: '三重禮',
  cardThreeDesc: '參與融資活動的客戶，累計最高可使用300萬港元，直至活動結束；逾期不予計入',
  cardThreeStep1: '領取免利息福利',
  cardThreeStep2: '資產 - 融資',
  cardThreeStep3: '融資交易',
  cardThreeStep4: '活動結束',
  cardThreeStep5: '利息返現',
  cardThreeStep6: '到帳',
  cardThreeTips: '提示：活動期間免息額度所產生的利息，採用先收後返原則。',

  activityRule: `
    一、活動對象：盈寶證券使用者<br/>
    二、活動時間：2023.12.22 0:00--2024.1.31 23:00<br/>
    三、活動條件及獎勵<br/>
    一重禮活動規則：<br/>
    1）活動期間，成功首次入金達到對應要求即可獲得對應的股票卡；自入金到帳次日起30天內無出金記錄且30天內交易1次可獲得對應的股票券。（特別說明：交易一次港股或美股一次，不含申購貨幣基金及新股） 註：活動參與條件為未入過金的新用戶即首次入金用戶。首次入金成功（20000，30000】的用戶，可獲得價值HKD3800新人禮包，具體以新人活動為準。<br/>
    2）活動期間，首次入金成功（30001，50000】的用戶，可獲得價值HKD3800新人禮包+福特 (NYSE: F) - 1股，需滿足留存30天且交易≥1次。<br/>
    3）活動期間，首次入金成功（50001，100000】的用戶，可獲得價值HKD3800新人禮包+福特 (NYSE: F) - 2股，需滿足留存30天且交易≥1次。<br/>
    4）活動期間，首次入金成功（100001，200000】的用戶，可獲得價值HKD3800新人禮包+福特 (NYSE: F) - 3股，需滿足留存30天且交易≥1次。<br/>
    5）活動期間，首次入金成功（200001，300000】的用戶，可獲得價值HKD3800新人禮包+福特 (NYSE: F) - 5股，需滿足留存30天且交易≥1次。<br/>
    6）價值HKD3800新人禮包：註冊就送88HKD禮包（30天美股lv1行情券）、開戶就送2256HKD禮包（180天港股lv2+180天美股lv1）、首筆入金金額達標最高贈送1500HKD現金。註：實際以新人活動規則為準。<br/><br/>
    二重禮活動規則：<br/>
    1）活動期間，用戶每完成一個梯度的港美股日內融交易筆數要求（買賣各算一筆），可獲得對應獎勵；<br/>
    2）客戶活動期間累計交易港/美股日內融30筆獲得100現金，再交易20筆累計交易50筆 再獲得100現金，累計共獲得200現金，以此類推，最高可獲得1500現金。<br/>
    3）交易筆數要求：在港/美股日內融交易中，買賣各算一筆。交易須在活動期間（北京時間）內完成成交，超出活動時間的交易不計入交易筆數。特別說明，交易美股每筆金額不得低於10美元，且每筆低於10美元的成交將不算再累積筆數當中。<br/><br/>
    三重禮活動規則：<br/>
    1）參與活動的用戶在活動期間均可使用日均10萬港元的孖展融資免息額度，累計300w的免息額度，直至活動結束；孖展融資免息額度使用時間截止至活動最後1天 ，逾期不予計入。（註：融資申購新股不參與此活動）<br/>
    2）用戶若在活動期間使用孖展融資額度超過免利息的部分，超出部分將正常收取費用，融資金額及產生的利息可查詢日結單，如有任何查詢，請致電本公司客服部。<br/>
    3）活動期間內單筆免息額度不得超過用戶保證金帳戶本身的可抵押金額；用戶在活動期間累計免息額度最高不超過300萬港元；（如用戶A在活動期間1天內用了300萬， 後續再融資則需要收取費用，以實際超出的融資額度進行計息。）<br/>
    4）活動獎勵：活動期間免息額度所產生的利息，採用先收後返原則，即在活動過程中正常收取，活動結束後統一返現到證券帳戶。<br/><br/>

    四、獎勵發放說明<br/>
    一重禮獎勵說明：<br/>
    1）首次入金達標可獲得對應的價值HKD3800新人禮包，具體可查看新人活動獎勵規則。<br/>
    2）股票卡需滿足對應的新增資金要求，達標後系統會自動發放股票卡券至個人帳戶，可前往我的卡券查看，逾期未兌換，不另補發。<br/><br/>
    二重禮獎勵說明：<br/>
    1）現金券需符合對應的交易筆數要求，達標後系統會自動發放卡券至個人帳戶，逾期未兌換，不另補發; <br/>
    2）現金券獎勵將卡券形式發放領取有效期限為15天，逾期不另補發；<br/>
    3）交易資料同步至卡券系統有1個交易日延時，請在完成符合指定條件的訂單後下一個交易日再行查看使用；<br/>
    4）卡券使用說明最終以「我的卡券」中為準。<br/><br/>
    三重禮獎勵說明：<br/>
    1）活動期間，正股融資利息採用先收後返原則，活動結束後的15個工作天內，活動免息部分將統一以港元幣種返還到用戶的盈寶賬戶，因不同幣種的匯率 有變化，最終返還金額的換算匯率以我司係統資料為準。<br/><br/>

    五、其他細則及免責聲明<br/>
    1）同一用戶僅能參與一次活動，同一盈寶ID號、同一手機號、同一設備均視為同一用戶；<br/>
    2）活動獎勵均嚴格依照規則發放，如有任何不符合活動得獎資格的情況，均無法特別申請補發獎勵，請遵守活動規則；<br/>
    3）活動獎勵設定有效期，若未在有效期限內及時使用，過期後將不予以補發獎勵，視為自動放棄獲獎資格；<br/>
    4）如發現包括但不限於透過軟體惡意刷獎、手機號碼異常、竊取他人資訊、邀請人IP異常等行為，盈寶證券有權取消或凍結其活動獎勵;<br/>
    5）此活動由盈寶證券國際(香港)有限公司("盈寶證券")提供。本活動並非及不應被視為任何證券、金融產品或工具邀約、要約、招攬、邀請、建議、意見或任何投資決策的見議，亦不應被視為專業意見。瀏覽本活動的人士在做出任何投資決定之前，應完全了解其風險以及相關法律，涉及的稅項及會計的特點及後果，並根據個人的情況決定投資是否適合個人的財政狀況及投資目標，以及 有關風險的承受能力，有需要時應尋找適當的專業意見。本廣告由本公司提供，內容未經香港證券及期貨事務監察委員會審閲。盈寶證券保留更改活動條款及細則⼂更改或終止任何優惠的權利，而毋須另行通知。本活動的獎勵資格及資料確認以盈寶證券後台資料為準，若有任何爭議，盈寶證券保留本活動的最終解釋權及決定權，如有問題可諮詢線上客服或電話： 香港：85223709538， 內地：4008016688。<br/>
  `
}
