<template>
  <div class="app-bar-wrap" :class="{ light: isScroll }">
    <div class="app-bar">
      <div class="left" @click="$emit('back')">
        <img :src="backIcon" />
      </div>
      <div class="title">
        <span v-if="isScroll">{{ title }}</span>
      </div>
      <div class="right" @click="$emit('share')">
        <img :src="shareIcon" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'app-bar',

    props: {
      title: {
        type: String,
        default: ''
      },
      defaultActionMode: {
        type: String,
        default: 'black'
      }
    },

    data() {
      return {
        isScroll: false
      }
    },

    computed: {
      backIcon() {
        if (this.defaultActionMode === 'white') {
          if (this.isScroll) {
            return require(`@/assets/images/activitys/icon_back_black.png`)
          } else {
            return require(`@/assets/images/activitys/icon_back_white.png`)
          }
        } else {
          return require(`@/assets/images/activitys/icon_back_black.png`)
        }
      },

      shareIcon() {
        if (this.defaultActionMode === 'white') {
          if (this.isScroll) {
            return require(`@/assets/images/activitys/share_black.png`)
          } else {
            return require(`@/assets/images/activitys/share_white.png`)
          }
        } else {
          return require(`@/assets/images/activitys/share_black.png`)
        }
      }
    },

    mounted() {
      const _this = this
      window.addEventListener(
        'scroll',
        function() {
          _this.isScroll = this.scrollY > 0
        },
        true
      )
    }
  }
</script>

<style lang="scss" scoped>
  .app-bar-wrap {
    width: 100%;
    height: 88px;
    background: transparent;
    padding-top: 44px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999;

    &.light {
      background: #fff;
    }

    .app-bar {
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;

      .left {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 12px;
        cursor: pointer;

        > img {
          width: 13px;
          height: 20px;
        }
      }

      .title {
        flex: 1;
        font-size: 18px;
        font-weight: 500;
        color: #121212;
        line-height: 18px;
        text-align: center;
      }

      .right {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 12px;
        cursor: pointer;

        > img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
</style>
