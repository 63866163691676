<template>
  <div class="wb-footer">
    <div class="wb-footer-content">
      <wb-connect />
      <wb-footer-info />
    </div>
  </div>
</template>

<script>
  import WbConnect from '@/components/footer/wb-connect.vue'
  import WbFooterInfo from '@/components/footer/wb-footer-info.vue'
  export default {
    name: 'wb-footer',

    components: {
      WbConnect,
      WbFooterInfo
    }
  }
</script>

<style lang="scss" scoped>
.wb-footer {
  background: #424242;
  position: relative;
  .wb-footer-content {
    margin: 0 auto;
  }

  .splite-line {
    width: 100%;
    height: 1px;
    background: #cacaca;
    transform: scaleY(0.5);
  }
}
</style>
