// activityStockTrans
export default {
  immediatelyOpen: '立即开户',
  activityRule: '活动规则',
  activityTime: '活动时间',

  routerTitle: '转仓活动',

  shareTitle: '转仓盈宝交易',
  shareDesc: '报销转仓手续费 送免佣大补贴',

  joinActivity: '参与活动',
  bannerText1: '报销 转仓手续费',
  bannerText2: '送 免佣大补贴',
  bannerTipTitle: '温馨提示：',
  bannerTips1: '1、活动面向未入金且未转仓的用户',
  bannerTips2: '2、转仓报销费用以实际产生的为准，需提供扣费凭证给在线客服登记或者发邮件登记（services@winbull.hk），需在成功转仓后的7个工作日内完成，若超过时间未申请将享受不了报销补贴。',

  cardOneTitle: '港美股转仓操作 二选一',
  cardOneTips1: '注：需保持30天内不出金并且交易≥1次',
  cardOneIntroTitle1: '01. 港股累计转仓 ≥ HKD 30,000',
  cardOneIntroDesc11: '30天港股免佣券 x 2',
  cardOneIntroDesc12: '最高报销 500港元转仓费用',
  cardOneIntroWidth: '或',
  cardOneIntroTitle2: '02. 美股累计转仓 ≥ USD 4,000',
  cardOneIntroDesc21: '30天美股免佣券 x 2',
  cardOneIntroDesc22: '最高报销 100美元转仓费用',
  cardOneTips2: '注：转仓报销费用以实际产生的为准，需提供扣费凭证给在线客服登记或者发邮件登记（services@winbull.hk），需在成功转仓后的7个工作日内完成，若超过时间未申请将享受不了报销补贴。',

  cardTwoTitle: '如何转仓到盈宝',
  cardTwoText1: '选择转出券商',
  cardTwoText2: '填写股票信息',
  cardTwoText3: '联系对方券商',
  cardTwoTips1: '具体操作指引',
  cardTwoTips2: '港股转入说明',
  cardTwoTips3: '美股转入说明',

  cardThreeTitle: '为什么选择盈宝',
  cardThreeIntroTitle1: '持牌券商（编号：BRG131）',
  cardThreeIntroDesc1: '获认可的互联网券商，拥有1、4、9牌照',
  cardThreeIntroTitle2: '快人一步',
  cardThreeIntroDesc2: '毫秒级下单速度，第一时间掌握交易先机',
  cardThreeIntroTitle3: '一站式交易',
  cardThreeIntroDesc3: '支持港股、美股、基金等投资标的',
  cardThreeIntroTitle4: '极致客户服务',
  cardThreeIntroDesc4: '7x24小时在线客服，一对一解答疑问'
}
